import { env } from '@/utils/env'

const config = {
  app: {
    title: 'Stride',
    tagline: 'Multichain Liquid Staking'
  },

  network: {
    mainnetChainId: 'stride-1',
    mintscanTxUrl: env('STRIDE_MINTSCAN_TX_URL', 'https://mintscan.io/stride/txs'),
    launchDate: '2022-09-04',
    faucetApiUrl: env('STRIDE_FAUCET_API_URL', 'https://faucet.stride.zone'),
    announcementRefetchInterval: 60 * 1000
  },

  ibc: {
    timeoutTesting: env('IBC_TIMEOUT_TESTING') === 'true',
    returnLaterMs: Number(env('IBC_RETURN_LATER_MS', String(60_000)))
  },

  upstashRedis: {
    main: {
      url: env('UPSTASH_REDIS_MAIN_URL', 'https://usw1-hot-bat-33320.upstash.io/'),
      // @TODO: https://github.com/Stride-Labs/interface/issues/89
      token: env(
        'UPSTASH_REDIS_MAIN_TOKEN',
        'AYIoASQgMGE0NDYyMjUtY2MxMy00ZjY0LTg0NmMtOTFlZmEyOTBiMjIzZWFmNDc3NjI0YWU1NDRlYThjY2UyMzU5MzNhNDRjZGI='
      )
    },

    airdrop: {
      url: env('UPSTASH_REDIS_AIRDROP_URL', 'https://us1-premium-caribou-39148.upstash.io'),
      // @TODO: https://github.com/Stride-Labs/interface/issues/89
      token: env(
        'UPSTASH_REDIS_AIRDROP_TOKEN',
        'AZjsASQgMDc1ODU1ZTktMzMwZi00OTM1LWI2YTAtOGYwYWY3NjY1MGMwZTY5ZmIzMjRlMGVmNDhmZThlMWY0MmU1OWM5NjQwNzA='
      )
    },

    announcement: {
      url: env('UPSTASH_REDIS_ANNOUNCEMENT_URL', 'https://us1-immense-dassie-37146.upstash.io'),
      // @TODO: https://github.com/Stride-Labs/interface/issues/89
      token: env(
        'UPSTASH_REDIS_ANNOUNCEMENT_TOKEN',
        'ApEaACQgZWM0NzUxZmYtNGFlYS00NWYyLWEzYjMtZmVkZWJhMTExNTA0E0vUq98wqi2KG59xHcukw2mQD7UuFHasIrIJfULO4TY='
      )
    }
  },

  rollbar: {
    token: env('ROLLBAR_TOKEN', 'aabbccddeeff112233'),
    env: env('ROLLBAR_ENV', process.env.NODE_ENV ?? 'local')
  },

  links: {
    website: 'https://stride.zone',
    github: 'https://github.com/Stride-Labs',
    docs: 'https://docs.stride.zone/docs',
    testnet: 'https://github.com/Stride-Labs/testnet',
    twitter: 'https://twitter.com/stride_zone',
    twitterHandle: '@stride_zone',
    discord: 'https://stride.zone/discord',
    email: 'mailto:hello@stridelabs.co',
    osmosis: 'https://app.osmosis.zone/',
    osmosisAssets: 'https://app.osmosis.zone/assets',
    faq: 'https://stride.zone/faq'
  }
} as const

export { config }
