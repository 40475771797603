import React, { useState, useMemo } from 'react'
import { UnstyledButton, Box, Text, Paper, Loader, Group, Collapse, MantineColor, useMantineTheme } from '@mantine/core'
import { Icon, IconName, StaticActionIcon } from '@/components'
import { useMediaQuery } from '@mantine/hooks'

export type TransactionCardStatus = 'checking' | 'checking-failed' | 'pending' | 'complete' | 'error'

export interface TransactionCardProps {
  status: TransactionCardStatus
  title?: string | null
  description?: React.ReactNode
  onDismiss?: () => void
  action?: React.ReactNode
  children?: React.ReactNode
}

const TransactionCard: React.FC<TransactionCardProps> = ({
  status,
  title,
  onDismiss,
  action,
  children,
  description
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const t = useMantineTheme()

  const isMediumScreen = useMediaQuery(`(min-width: ${t.breakpoints.md}px)`, true)

  const handleToggleOpen = () => {
    setIsOpen((isOpen) => !isOpen)
  }

  const isDismissible = useMemo(() => {
    return !['pending', 'checking', 'checking-failed'].includes(status)
  }, [status])

  const Wrapper = isDismissible ? Box : UnstyledButton

  const wrapperProps = isDismissible ? {} : { style: { width: '100%' }, onClick: handleToggleOpen }

  const icons: Record<Exclude<TransactionCardStatus, 'checking'>, IconName> = {
    'checking-failed': 'arrowHorizontal',
    pending: 'arrowHorizontal',
    complete: 'check',
    error: 'arrowHorizontal'
  }

  const colors: Record<Exclude<TransactionCardStatus, 'checking'>, MantineColor> = {
    'checking-failed': 'gray',
    pending: 'yellow',
    complete: 'cyan',
    error: 'red'
  }

  return (
    <Paper p={0} withBorder>
      <Wrapper {...wrapperProps}>
        <Group p="md" align="center" position="apart" noWrap={!isMediumScreen}>
          <Group spacing="sm" noWrap={!isMediumScreen} align={isMediumScreen ? 'center' : 'flex-start'}>
            {status === 'checking' ? (
              <Loader size="xs" color="gray" />
            ) : (
              <StaticActionIcon icon={icons[status]} color={colors[status]} />
            )}

            <Text weight={600} size="md" inline={isMediumScreen} sx={(t) => ({ color: t.colors.gray[9] })}>
              {title}
            </Text>
          </Group>

          <Box>
            {action ??
              (isDismissible ? (
                Boolean(onDismiss) && (
                  <UnstyledButton onClick={onDismiss}>
                    <Icon name="cross" size="xs" sx={(t) => ({ color: t.colors.gray[8] })} />
                  </UnstyledButton>
                )
              ) : (
                <Box sx={{ transform: isOpen ? '' : 'rotate(180deg)' }}>
                  <Icon name="caretDown" size="xs" sx={(t) => ({ color: t.colors.gray[8] })} />
                </Box>
              ))}
          </Box>
        </Group>
      </Wrapper>

      {Boolean(description || children) && (
        <Collapse in={isDismissible ? true : isOpen}>
          <Box p="lg" pt={0}>
            <Text sx={(t) => ({ color: t.colors.gray[7] })}>{description}</Text>
            {children}
          </Box>
        </Collapse>
      )}
    </Paper>
  )
}

export { TransactionCard }
