import { createContext } from 'react'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { SignSendTokenMutationParameters } from './mutations/useSignSendToken'
import {
  BroadcastSendTokenMutationParameters,
  BroadcastSendTokenMutationReturnType
} from './mutations/useBroadcastSendToken'
import { BroadcastStakeTokenParameters } from './mutations/useBroadcastStakeToken'
import { UseClaimTokensParameters } from './mutations/useClaimTokens'
import { TxRawEip712 } from './ibc'

interface WalletContextType {
  connectWallet: () => void
  disconnectWallet: () => void
  signSendToken: (params: SignSendTokenMutationParameters) => Promise<TxRaw | TxRawEip712>
  resetSignSendToken: () => void
  broadcastSendToken: (params: BroadcastSendTokenMutationParameters) => Promise<BroadcastSendTokenMutationReturnType>
  resetBroadcastSendToken: () => void
  signStakeToken: (amount: number) => Promise<TxRaw>
  resetSignStakeToken: () => void
  broadcastStakeToken: (params: BroadcastStakeTokenParameters) => Promise<DeliverTxResponse>
  resetBroadcastStakeToken: () => void
  signUnstakeTokens: (amount: number) => Promise<TxRaw>
  resetSignUnstakeTokens: () => void
  broadcastUnstakeTokens: (raw: TxRaw) => Promise<unknown>
  resetBroadcastUnstakeTokens: () => void
  claimToken: (params: UseClaimTokensParameters) => Promise<unknown>
  isConnectingWallet: boolean
  isFetchingSelectedAccount: boolean
  isFetchingOsmosisAccount: boolean
  isSigningSendToken: boolean
  isBroadcastingSendToken: boolean
  isSigningStakeToken: boolean
  isBroadcastingStakeToken: boolean
  isBroadcastStakeTokenSuccess: boolean
  isSigningUnstakeTokens: boolean
  isBroadcastingUnstakeTokens: boolean
  isClaimingToken: boolean
  signSendTokenError: unknown
  broadcastSendTokenError: unknown
  signStakeTokenError: unknown
  broadcastStakeTokenError: unknown
  signUnstakeTokensError: unknown
  broadcastUnstakeTokensError: unknown
  claimTokenError: unknown
}

const WalletContext = createContext<WalletContextType>({} as WalletContextType)

export { WalletContext }
