import { DeliverTxResponse } from '@cosmjs/stargate'
import { useQueryClient } from 'react-query'
import { useAtom } from 'jotai'
import { notify } from '@/atoms/Notifications'
import { fatal, disregard } from '@/utils'
import { queryKeys } from '@/query-keys'
import { useUpdateAccountBalances } from './useUpdateAccountBalances'
import { selectedAccountAtom, strideAccountAtom } from '../atoms'
import { traceIbcStatus, IBCTransferStatus } from '../ibc'

export type UseTraceIbcTransferMode = 'deposit' | 'withdraw'

export interface UseTraceIbcTransferParameters {
  tx: DeliverTxResponse
  mode: UseTraceIbcTransferMode
}

// A wrapper for traceIbcStatus that updates account balances afterwards.
// Out of the box, this hook disables the timeout after 30s (return-later)
// And so, it only resolves to either complete or timeout
// This hook is meant to be used in another query / mutation.
//
// @TODO: Let's re-implement this the same way we implemented
// useTraceIbcTransfer in Osmosis Liquidity Pools feature.
const useTraceIbcTransfer = () => {
  const client = useQueryClient()

  const [selectedAccount] = useAtom(selectedAccountAtom)

  const [strideAccount] = useAtom(strideAccountAtom)

  const { updateAccountBalances } = useUpdateAccountBalances()

  const traceIbcStatusFn = async ({ tx, mode }: UseTraceIbcTransferParameters): Promise<IBCTransferStatus> => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('You are unable to send token without connecting your wallet.')
    }

    const account = mode === 'deposit' ? selectedAccount : strideAccount

    const status = await traceIbcStatus({ account, tx }, { timeout: false })

    await client.invalidateQueries(queryKeys.transactionHistoryIbcStatusByHash({ hash: tx.transactionHash }))

    try {
      await updateAccountBalances(strideAccount)
      await updateAccountBalances(selectedAccount)
    } catch (e) {
      notify.error(`An error occured while fetching your updated account balance. Please refresh the page.`)
      disregard(e)
    }

    return status
  }

  return { traceIbcStatus: traceIbcStatusFn }
}

export { useTraceIbcTransfer }
