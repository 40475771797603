import axios from 'axios'
import { useAtomValue } from 'jotai'
import { useQuery } from 'react-query'
import { queryKeys } from '@/query-keys'
import { CHAIN_INFO_LIST, STRIDE_CHAIN_INFO } from '@/config'
import { selectedCoinDenomAtom } from '@/atoms/Wallet'
import { notify } from '@/atoms/Notifications'

export interface HostZone {
  chain_id: string
  connection_id: string
  transfer_channel_id: string
  bech32prefix: string
  ibc_denom: string
  host_denom: string
  last_redemption_rate: string
  redemption_rate: string
  unbonding_frequency: string
  staked_bal: string
  address: string
}

interface HostZoneResponse {
  host_zone: HostZone
}

const useHostZoneQuery = () => {
  const denom = useAtomValue(selectedCoinDenomAtom)

  const chainInfo = CHAIN_INFO_LIST[denom]

  const handleRequest = async () => {
    const instance = axios.create({
      baseURL: STRIDE_CHAIN_INFO.rest
    })

    return await instance.get<HostZoneResponse>(`Stride-Labs/stride/stakeibc/host_zone/${chainInfo.chainId}`)
  }

  const handleError = () => {
    notify.error('Could not fetch host zone details. Please refresh the page')
  }

  return useQuery(queryKeys.globalHostZoneFromChainId({ chainId: chainInfo.chainId }), handleRequest, {
    retry: 5,
    retryDelay: 500,
    onError: handleError,
    select: (response) => response.data.host_zone
  })
}

export { useHostZoneQuery }
