import type { AppProps } from 'next/app'
import Head from 'next/head'
import { Global } from '@mantine/core'
import { RollbarProvider } from '@/atoms/Rollbar'
import { NotificationsProvider } from '@/atoms/Notifications'
import { QueryClientProvider } from '@/atoms/Query'
import { ColorSchemeProvider } from '@/atoms/ColorScheme'
import { MantineProvider } from '@/atoms/Mantine'
import { WalletProvider } from '@/atoms/Wallet'
import { WalletConnectionProvider } from '@/atoms/WalletConnection'
import { TopBannerProvider } from '@/atoms/TopBanner'
import { GlobalLayout } from '@/page-components/GlobalLayout'
import { config } from '@/config'

function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>
          {config.app.title} | {config.app.tagline}
        </title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <meta content="A protocol for multichain liquid staking in the Cosmos ecosystem" name="description" />
        <meta content="Stride | Multichain Liquid Staking" property="og:title" />
        <meta content="A protocol for multichain liquid staking in the Cosmos ecosystem" property="og:description" />
        <meta content="/stride-og.png" property="og:image" />
        <meta content="Stride | Multichain Liquid Staking" property="twitter:title" />
        <meta
          content="A protocol for multichain liquid staking in the Cosmos ecosystem"
          property="twitter:description"
        />
        <meta content="/stride-og.png" property="twitter:image" />
        <meta property="og:type" content="website" />
        <meta content="summary_large_image" name="twitter:card" />
        <link href="/favicon.png" rel="shortcut icon" type="image/x-icon" />
        <link href="/webclip.png" rel="apple-touch-icon" />
      </Head>

      <ColorSchemeProvider>
        <MantineProvider>
          {/* We are placing this here so we can style our fallback UI when our app crashes. */}
          {/* For awareness, this strongly assumes that both providers above won't crash. */}
          <RollbarProvider>
            <QueryClientProvider>
              <NotificationsProvider>
                <TopBannerProvider>
                  <WalletConnectionProvider>
                    <WalletProvider>
                      <GlobalLayout>
                        <Component {...pageProps} />
                      </GlobalLayout>
                    </WalletProvider>
                  </WalletConnectionProvider>
                </TopBannerProvider>
              </NotificationsProvider>
            </QueryClientProvider>
          </RollbarProvider>

          <Global
            styles={{
              '*, *::before, *::after': {
                boxSizing: 'border-box'
              },

              body: {
                background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 47.4%), #E5FCFF',
                backgroundRepeat: 'no-repeat'
              }
            }}
          />
        </MantineProvider>
      </ColorSchemeProvider>
    </>
  )
}

export default App
