import WalletConnect from '@walletconnect/client'

interface CreateWalletConnectClientParameters {
  onOpen: (uri: string, cb: Function) => void
  onClose: () => void
}

const createWalletConnectClient = ({ onOpen, onClose }: CreateWalletConnectClientParameters): WalletConnect => {
  return new WalletConnect({
    bridge: 'https://bridge.walletconnect.org',
    signingMethods: ['keplr_enable_wallet_connect_v1', 'keplr_sign_amino_wallet_connect_v1'],
    qrcodeModal: {
      open: onOpen,
      close: onClose
    }
  })
}

export { createWalletConnectClient }
