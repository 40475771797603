import * as React from 'react'
import { SVGProps } from 'react'
const SvgTrophy = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 14 13" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.333.667V6a3.333 3.333 0 0 1-6.666 0V.667M4.333 12h5.334-5.334ZM7 9.333V12 9.333ZM3.667.667h6.666-6.666Z"
      stroke="#15AABF"
      strokeWidth={1.333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.333 5.333a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667ZM11.667 5.333a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667Z"
      stroke="#15AABF"
      strokeWidth={1.333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgTrophy
