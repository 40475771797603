import React from 'react'
import { Box, Group, MantineColor, Text, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

interface StatusBadgeProps {
  color: MantineColor
  className?: string
  children: React.ReactNode
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ color, className = '', children }) => {
  const theme = useMantineTheme()

  const isMediumScreen = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`, true)

  return (
    <Box sx={{ opacity: 0.7 }}>
      <Group align="center" className={className} spacing="xs">
        <Box
          sx={(t) => ({
            height: 8,
            width: 8,
            background: color === 'red' ? t.colors.red[8] : t.fn.variant({ variant: 'filled', color }).background,
            borderRadius: '50%'
          })}
        />

        <Text
          transform="uppercase"
          weight={700}
          size="xs"
          sx={(t) => ({ color: t.colors.gray[9], fontSize: !isMediumScreen ? t.other.statusBadge.fontSizeMobile : t.other.statusBadge.fontSizeDesktop })}>
          {children}
        </Text>
      </Group>
    </Box>
  )
}

export { StatusBadge }
