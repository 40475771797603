import React, { useMemo } from 'react'
import { useAtomValue } from 'jotai'
import { Box, Center, Text } from '@mantine/core'
import { useMediumScreen } from '@/hooks'
import { selectedAccountAtom } from '@/atoms/Wallet'
import { useAnnouncementFlags } from './queries'
import { EVMOS_CHAIN_INFO } from '@/config'

const GlobalAnnouncement = () => {
  const { data } = useAnnouncementFlags()

  const selectedAccount = useAtomValue(selectedAccountAtom)

  const message = useMemo(() => {
    if (data?.maintenance && data?.upgrade) {
      return 'Stride is currently undergoing maintenance and a scheduled upgrade, performance degradation may occur.'
    }

    if (data?.upgrade) {
      return 'Stride is currently undergoing a scheduled upgrade, performance degradation may occur.'
    }

    if (data?.maintenance) {
      return 'Stride is currently undergoing maintenance, performance degradation may occur.'
    }

    if (
      selectedAccount &&
      selectedAccount.currency.coinDenom === EVMOS_CHAIN_INFO.stakeCurrency.coinDenom &&
      selectedAccount.key.isNanoLedger
    ) {
      return "Evmos currently does not support transactions with Ledger. This is expected to be resolved with Evmos' v12 software upgrade."
    }

    return ''
  }, [data, selectedAccount])

  const { isMediumScreen } = useMediumScreen()

  if (!message) {
    return null
  }

  return (
    <Box sx={(t) => ({ background: t.colors.yellow[1], padding: t.other.globalAnnouncement.padding })}>
      <Center>
        <Text inline={isMediumScreen} sx={(t) => ({ color: t.colors.gray[7] })}>
          {message}
        </Text>
      </Center>
    </Box>
  )
}

export { GlobalAnnouncement }
