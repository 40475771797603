import React, { useMemo } from 'react'
import { useAtom } from 'jotai'
import { Box, Center, Modal, Title, Text, Space, Group, Button, Image } from '@mantine/core'
import { WALLET_LIST } from '@/config'
import { missingExtensionWalletIdAtom } from '@/atoms/WalletConnection'
import { CustomModalCloseButton } from './CustomModalCloseButton'

const MissingExtensionModal = () => {
  const [missingExtensionWalletId, setMissingExtensionWalletId] = useAtom(missingExtensionWalletIdAtom)

  const handleClose = () => {
    // Close only after the event loop.
    // Otherwise, our dialogue closes too fast and the download link won't open on a new tab as intended.
    setTimeout(() => setMissingExtensionWalletId(undefined), 0)
  }

  const walletInfo = useMemo(() => {
    if (missingExtensionWalletId == null) return
    return WALLET_LIST.find((info) => info.id === missingExtensionWalletId)
  }, [missingExtensionWalletId])

  return (
    <Modal opened={Boolean(missingExtensionWalletId)} onClose={handleClose} size="md" withCloseButton={false}>
      <CustomModalCloseButton onClick={handleClose} />

      <Center>
        <Box
          sx={(t) => ({
            padding: t.spacing.xs,
            maxWidth: t.other.globalWalletConnectionWalletInstallModal.logoMaxWidth,
            borderRadius: t.radius.md,
            border: `1px dashed ${t.colors.gray[4]}`,
            lineHeight: t.other.globalWalletConnectionWalletInstallModal.titleLineHeight
          })}>
          <Image
            src={walletInfo?.extension?.customLogoImgUrl ?? walletInfo?.logoImgUrl}
            alt={`${walletInfo?.name} Logo`}
            sx={{ display: 'block' }}
          />
        </Box>
      </Center>
      <Space h="md" />

      <Title order={3} align="center" sx={(t) => ({ color: t.colors.gray[9] })}>
        Missing {walletInfo?.name} Extension
      </Title>

      <Space h="xs" />

      <Text align="center" sx={(t) => ({ color: t.colors.gray[7] })}>
        {walletInfo?.extension?.missingMessage}
      </Text>

      <Space h="lg" />

      <Group position="apart">
        <Button variant="default" onClick={handleClose}>
          Close
        </Button>

        <Button component="a" href={walletInfo?.extension?.downloadUrl} target="_blank" onClick={handleClose}>
          Install {walletInfo?.name}
        </Button>
      </Group>
    </Modal>
  )
}

export { MissingExtensionModal }
