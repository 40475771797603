import { DeliverTxResponse } from '@cosmjs/stargate'
import { config } from '@/config'
import { fatal } from '@/utils'
import { Buffer } from 'buffer'
import { TxEvent } from '../types'
import { parseTxRawLog, isSendPacketEvent, isSendPacketAttributeKey, SendPacketEventObject } from './type-guards'

const TIMEOUT_MS = config.ibc.timeoutTesting
  ? 5 * 1000 * 1000 * 1000 // 5s
  : 5 * 60 * 1000 * 1000 * 1000 // 5m

export const generateIbcTimeoutTimestamp = () => {
  return new Date().getTime() * 1_000_000 + TIMEOUT_MS
}

export const convertIbcTimeoutTimestampToDate = (timeoutTimestamp: string) => {
  return new Date(Number(timeoutTimestamp) / 1_000_000)
}

// Get the attributes of the send_packet event
export const getSendPacketAttributes = (events: TxEvent[]): SendPacketEventObject | null => {
  for (const event of events) {
    if (isSendPacketEvent(event)) {
      return event.attributes.reduce((object, attr) => {
        object[attr.key] = attr.value
        return object
      }, {} as SendPacketEventObject)
    }
  }

  // There are apparently case where send_packet would not exist. When that happens
  // let's make sure that we handle this - either throw fatal if we're sure it should exist
  // or log to Rollbar if not.
  return null
}

// Similar to the above function to be used for events that are not yet decoded.
export const getHashedSendPacketAttributes = (events: TxEvent[]): SendPacketEventObject | null => {
  for (const event of events) {
    if (isSendPacketEvent(event)) {
      return event.attributes.reduce((object, attr) => {
        const key = Buffer.from(attr.key, 'base64').toString()
        if (!isSendPacketAttributeKey(key)) {
          return object
        }
        object[key] = Buffer.from(attr.value, 'base64').toString()
        return object
      }, {} as SendPacketEventObject)
    }
  }

  // There are apparently case where send_packet would not exist. When that happens
  // let's make sure that we handle this - either throw fatal if we're sure it should exist
  // or log to Rollbar if not.
  return null
}

// Parse tx.logs and get the attributes of the send_packet event
export const getTxRawLogSendPacketAttributes = (tx: DeliverTxResponse): SendPacketEventObject => {
  const log = parseTxRawLog(tx)

  const packets = getSendPacketAttributes(log.events)

  if (packets == null) {
    throw fatal(
      `Attempt to trace ibc status of ${tx.transactionHash} failed. 'send_packet' attributes are missing from the transaction raw log.`
    )
  }

  return packets
}
