import React from 'react'
import { StatusBadge } from '@/components'
import { useAtom } from 'jotai'
import { config, STRIDE_CHAIN_INFO } from '@/config'
import { strideAccountAtom } from '@/atoms/Wallet'

const NavigationNetworkStatus: React.FC = () => {
  const [strideAccount] = useAtom(strideAccountAtom)

  if (strideAccount && STRIDE_CHAIN_INFO.chainId === config.network.mainnetChainId) {
    return <StatusBadge color="cyan">Mainnet</StatusBadge>
  }

  if (strideAccount) {
    return <StatusBadge color="yellow">Testnet</StatusBadge>
  }

  return <StatusBadge color="red">Not Connected</StatusBadge>
}

export { NavigationNetworkStatus }
