import React, { useMemo } from 'react'
import { Box, Group, Modal, Title, Stepper, Space, Stack, UnstyledButton, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useStepperContext } from './StepperProvider'
import { Icon } from '@/components'

interface StepperModalProps {
  title: string
  amount: React.ReactElement | string
  children: React.ReactNode
}

const StepperModal: React.FC<StepperModalProps> = ({ title, amount, children }) => {
  const theme = useMantineTheme()

  const isMediumScreen = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`, true)

  const { activeStep, stepKey, steps, warning, error, isOpen, isLoading, isOnGoing, isComplete, forceClose, close } =
    useStepperContext()

  const stepperActiveStep = useMemo(() => {
    if (isComplete) return steps.length + 1
    if (isOnGoing) return activeStep
    return -1
  }, [steps, activeStep, isOnGoing, isComplete])

  const activeStepNode = useMemo(() => {
    return React.Children.toArray(children).find((_, i) => {
      return i === activeStep
    })
  }, [children, activeStep])

  return (
    <Modal
      opened={isOpen}
      onClose={close}
      size={isMediumScreen ? theme.other.stepperModal.bodyWidth : '100%'}
      padding={0}
      radius={isMediumScreen ? 'lg' : 0}
      withCloseButton={false}
      styles={() => ({
        // full screen styling
        inner: { ...(!isMediumScreen && { padding: 0 }) },
        modal: {
          ...(!isMediumScreen && { height: '100%' })
        }
      })}>
      <Group
        spacing={0}
        align="stretch"
        sx={(t) => ({
          backgroundColor: t.white,
          borderRadius: isMediumScreen ? t.radius.lg : 0
        })}>
        <Box
          sx={(t) => ({
            padding: isMediumScreen ? t.spacing.lg : t.spacing.md,
            width: isMediumScreen ? t.other.stepperModal.wizardWidth : '100%',
            background: t.colors.gray[0],
            borderTopLeftRadius: isMediumScreen ? t.radius.lg : 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: isMediumScreen ? t.radius.lg : 0,
            borderBottomRightRadius: 0
          })}>
          <Title order={6} sx={(t) => ({ color: t.colors.gray[7], fontWeight: 600, textTransform: 'uppercase' })}>
            {title}
          </Title>

          <Title order={3} sx={(t) => ({ color: t.colors.gray[9] })}>
            {amount}
          </Title>

          <Space h="lg" />

          <Stepper active={stepperActiveStep} orientation="vertical">
            {steps.map((step, i) => {
              const isStepActive = i === activeStep

              const color = isStepActive && warning ? 'yellow' : isStepActive && error ? 'red' : undefined

              return (
                <Stepper.Step
                  description={step}
                  loading={isStepActive && (isLoading || warning)}
                  progressIcon={!warning && error ? <Icon name="cross" /> : undefined}
                  color={color}
                  key={i}
                />
              )
            })}
          </Stepper>
        </Box>

        <Stack
          spacing={0}
          justify="center"
          sx={(t) => ({
            position: 'relative',
            padding: isMediumScreen ? t.other.stepperModal.contentPaddingDesktop : t.spacing.md,
            width: t.other.stepperModal.contentWidth
          })}>
          <React.Fragment key={stepKey}>{activeStepNode}</React.Fragment>
        </Stack>
      </Group>

      <UnstyledButton
        sx={{ position: 'absolute', top: theme.spacing.xl, right: theme.spacing.xl }}
        onClick={forceClose}>
        <Icon name="cross" sx={(t) => ({ color: t.colors.gray[8] })} />
      </UnstyledButton>
    </Modal>
  )
}

export { StepperModal }
