import React from 'react'
import QRCode from 'qrcode.react'
import { useAtomValue } from 'jotai'
import Image from 'next/image'
import { Box, Center, Modal, Title, Text, Space, Group, Button, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { wcQrUriAtom } from '@/atoms/WalletConnection'
import walletConnectLogo from '@/images/wallets/keplr-walletconnect-app.png'
import { useWalletConnection } from '@/atoms/WalletConnection'

const WalletConnectQRModal = () => {
  const wcQrUri = useAtomValue(wcQrUriAtom)

  const { closeWcModal } = useWalletConnection()

  const modalProps = {
    opened: Boolean(wcQrUri),
    onClose: closeWcModal,
    size: 'md'
  }

  const theme = useMantineTheme()

  const isMediumScreen = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`, true)

  if (isMediumScreen) {
    return (
      <Modal
        {...modalProps}
        withCloseButton
        title="WalletConnect"
        styles={(t) => ({
          title: {
            fontSize: t.fontSizes.lg,
            fontWeight: 600,
            color: t.colors.gray[9]
          },
          header: {
            marginBottom: 8,
            lineHeight: 1
          }
        })}>
        <Text sx={(t) => ({ color: t.colors.gray[7] })}>
          Scan the QR code below to connect to Stride with WalletConnect.
        </Text>

        <Space h={40} />

        <QRCode style={{ width: '100%', height: '100%' }} size={500} value={wcQrUri} />
      </Modal>
    )
  }

  return (
    <Modal {...modalProps} withCloseButton>
      <Center>
        <Box
          sx={(t) => ({
            padding: t.spacing.xs,
            maxWidth: t.other.globalWalletConnectionWcQrModal.logoMaxWidth,
            borderRadius: t.radius.md,
            border: `1px dashed ${t.colors.gray[4]}`,
            lineHeight: t.other.globalWalletConnectionWcQrModal.titleLineHeight
          })}>
          <Image src={walletConnectLogo} alt="Keplr Logo" />
        </Box>
      </Center>
      <Space h="md" />

      <Title order={3} align="center" sx={(t) => ({ color: t.colors.gray[9] })}>
        Unsupported Wallet
      </Title>

      <Space h="xs" />

      <Text align="center" sx={(t) => ({ color: t.colors.gray[7] })}>
        Staking on mobile devices is not supported at the moment. For the mean time, we recommend that you try Stride on
        your desktop device.
      </Text>

      <Space h="lg" />

      <Group position="apart">
        <Box />

        <Button variant="default" onClick={closeWcModal}>
          Close
        </Button>
      </Group>
    </Modal>
  )
}

export { WalletConnectQRModal }
