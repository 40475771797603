import { CHAIN_CONFIG, STRIDE_CURRENCY } from '@/config'
import { ellipsis } from '@/utils'
import { atom } from 'jotai'
import { atomWithImmer } from 'jotai/immer'
import { atomWithStorage } from 'jotai/utils'
import { Account, SelectedCoinDenom, TxMetaData } from './types'

const accountsAtom = atomWithImmer<Account[]>([])

const selectedCoinDenomAtom = atom<SelectedCoinDenom>('ATOM')

const selectedAccountAtom = atom((get) => {
  return get(accountsAtom).find((account) => {
    return account.currency.coinDenom === get(selectedCoinDenomAtom)
  })
})

const selectedAccountShortAddressAtom = atom((get) => {
  const account = get(selectedAccountAtom)
  return account ? ellipsis(account.key.bech32Address, 20) : ''
})

// e.g. ATOM on Cosmos
const selectedAccountBalanceAtom = atom((get) => {
  return Number(get(selectedAccountAtom)?.coin.amount || 0)
})

// e.g. stATOM on Cosmos
const selectedAccountStakedBalanceAtom = atom((get) => {
  const selectedAccount = get(selectedAccountAtom)

  return Number(
    selectedAccount?.coins.find((coin) => {
      return coin.denom === CHAIN_CONFIG[selectedAccount.currency.coinDenom].stakedIbcDenom
    })?.amount || 0
  )
})

const selectedAccountDenomAtom = atom((get) => {
  return get(selectedAccountAtom)?.currency.coinDenom || get(selectedCoinDenomAtom)
})

const strideAccountAtom = atom((get) => {
  return get(accountsAtom).find((account) => {
    return account.currency.coinDenom === STRIDE_CURRENCY.coinDenom
  })
})

const strideAccountShortAddressAtom = atom((get) => {
  const account = get(strideAccountAtom)
  return account ? ellipsis(account.key.bech32Address, 20) : ''
})

const strideAccountBalanceAtom = atom((get) => {
  return Number(get(strideAccountAtom)?.coin.amount || 0)
})

// e.g. ATOM on Stride
const strideAccountSelectedCoinAmountAtom = atom((get) => {
  return Number(
    get(strideAccountAtom)?.coins.find((coin) => {
      return coin.denom === CHAIN_CONFIG[get(selectedCoinDenomAtom)].ibcDenom
    })?.amount || 0
  )
})

// e.g. stATOM on Stride
const strideAccountSelectedStakedCoinAmountAtom = atom((get) => {
  return Number(
    get(strideAccountAtom)?.coins.find((coin) => {
      return coin.denom === `st${get(selectedAccountAtom)?.currency.coinMinimalDenom}`
    })?.amount || 0
  )
})

const osmosisAccountAtom = atom((get) => {
  return get(accountsAtom).find((account) => {
    return account.currency.coinDenom === 'OSMO'
  })
})

// Contains transactions with hand-picked useful, relevant (sometimes formatted) attributes
const pendingTxsMetaDataAtom = atom<TxMetaData[]>([])

// @TODO: Add expiration date
// Flag used to check if wallet was previously connected; we'll use it
// to auto-connect wallet en page loads - if locked - then load key and balance.
const sessionWalletTypeAtom = atomWithStorage<string>('stride.sessionWalletType', '')

export {
  accountsAtom,
  selectedCoinDenomAtom,
  selectedAccountAtom,
  selectedAccountShortAddressAtom,
  selectedAccountBalanceAtom,
  selectedAccountStakedBalanceAtom,
  selectedAccountDenomAtom,
  strideAccountAtom,
  strideAccountShortAddressAtom,
  strideAccountBalanceAtom,
  strideAccountSelectedCoinAmountAtom,
  strideAccountSelectedStakedCoinAmountAtom,
  osmosisAccountAtom,
  sessionWalletTypeAtom,
  pendingTxsMetaDataAtom
}
