// https://github.com/cosmology-tech/cosmodal

// This config file including the components consuming it from `page-components/GlobalWalletConnection`
// are based on the npm package `cosmodal`, modified to work within our codebase.
import cosmostationWalletLogo from '@/images/wallets/cosmostation-wallet-extension.png'
import keplrWalletMissingLogo from '@/images/wallets/keplr-missing-logo.svg'
import keplrWalletLogo from '@/images/wallets/keplr-wallet-extension.png'
import keplrWalletConnectLogo from '@/images/wallets/keplr-walletconnect-app.png'
import leapWalletLogo from '@/images/wallets/leap-wallet-extension.svg'
import { getKeplrFromWindow } from '@keplr-wallet/stores'
import { SUPPORTED_CHAIN_INFO_LIST } from './chain-info'
import { getCosmostationWallet } from '@/atoms/WalletConnection/cosmostation'
import { getLeapFromWindow } from '@/atoms/WalletConnection/leap'
import { KeplrWalletConnectV1 } from '@/atoms/WalletConnection/walletconnect/KeplrWalletConnectV1'
import { WalletInfo } from '@/atoms/WalletConnection/types'

const keplr: WalletInfo = {
  id: 'keplr-wallet-extension',
  name: 'Keplr Wallet',
  description: 'Keplr Browser Extension',
  logoImgUrl: keplrWalletLogo.src,
  getWallet: () => Promise.resolve(getKeplrFromWindow()),
  extension: {
    downloadUrl: 'https://www.keplr.app/download',
    missingMessage: `We're having trouble detecting Keplr in your system. We recommend installing Keplr through supported browsers to start staking on Stride now.`,
    customLogoImgUrl: keplrWalletMissingLogo.src
  },
  stakingUrl: 'https://wallet.keplr.app/?tab=staking'
}

const wc: WalletInfo = {
  id: 'walletconnect-keplr',
  name: 'WalletConnect',
  description: 'Wallet Connect',
  logoImgUrl: keplrWalletConnectLogo.src,
  getWallet: (params) => Promise.resolve(new KeplrWalletConnectV1(params.wc, SUPPORTED_CHAIN_INFO_LIST)),
  stakingUrl: ''
}

const leap: WalletInfo = {
  id: 'leap-wallet-extension',
  name: 'Leap Wallet',
  description: 'Leap Browser Extension',
  logoImgUrl: leapWalletLogo.src,
  getWallet: () => Promise.resolve(getLeapFromWindow()),
  extension: {
    downloadUrl: 'https://www.leapwallet.io/',
    missingMessage: `We're having trouble detecting Leap in your system. We recommend installing Leap through supported browsers to start staking on Stride now.`
  },
  stakingUrl: 'chrome-extension://fcfcfllfndlomdhbehjjcoimbgofdncg/index.html#/stake'
}

const cosmostation: WalletInfo = {
  id: 'cosmostation-wallet-extension',
  name: 'Cosmostation Wallet',
  description: 'Cosmostation Wallet Extension',
  logoImgUrl: cosmostationWalletLogo.src,
  getWallet: () => Promise.resolve(getCosmostationWallet()),
  extension: {
    downloadUrl: 'https://www.cosmostation.io/wallet/#extension',
    missingMessage: `We're having trouble detecting Cosmostation in your system. We recommend installing Cosmostation through supported browsers to start staking on Stride now.`
  },
  stakingUrl: 'https://wallet.cosmostation.io/cosmos/delegate'
}

const WALLET_LIST: WalletInfo[] = [keplr, wc, leap, cosmostation]

export { WALLET_LIST }
