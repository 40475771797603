import { SelectedCoinDenom } from '@/atoms/Wallet/types'

const AIRDROP_CONFIG = {
  startDate: '2022-11-22',
  endDate: '2023-02-20', // 90 days since startDate
  guideUrl: 'https://stride.zone/blog/stride-airdrop-details',
  weights: {
    claim: 0.2,
    stake: 0.2,
    liquidStake: 0.6
  }
} as const

// stride = airdrop for the testnet rewards
const AIRDROP_IDENTIFIERS = ['stride', 'gaia', 'osmosis', 'juno', 'stars'] as const

// 1 to 1 mapping from airdrop identifier to denom / chain info
const AIRDROP_IDENTIFIER_DENOMS: Record<typeof AIRDROP_IDENTIFIERS[number], SelectedCoinDenom> = {
  stride: 'STRD',
  gaia: 'ATOM',
  osmosis: 'OSMO',
  juno: 'JUNO',
  stars: 'STARS'
}

export { AIRDROP_CONFIG, AIRDROP_IDENTIFIERS, AIRDROP_IDENTIFIER_DENOMS }
