import { cosmos, InstallError } from '@cosmostation/extension-client'
import { Keplr } from '@keplr-wallet/types'
import { CosmostationKeplr } from './CosmostationKeplr'

const getCosmostationWallet = async (): Promise<Keplr | undefined> => {
  try {
    return new CosmostationKeplr(await cosmos())
  } catch (e) {
    if (e instanceof InstallError) {
      return undefined
    }

    throw e
  }
}

export { getCosmostationWallet }
