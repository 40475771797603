import React from 'react'
import Image from 'next/image'
import { Provider, ErrorBoundary } from '@rollbar/react'
import { Box, Container, Title, Text, Button, Space, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { rollbar, rollbarConfig } from '@/rollbar'
import logo from '@/images/logo.svg'

// Safe fallback UI for synchronous errors.
// For awareness, the errors we throw in asynchronous code (usually used through react-query) won't trigger the error boundary.
// And so, we should be handling those errors whenever we can (toast, proper error screen).
// @see https://tkdodo.eu/blog/react-query-error-handling#:~:text=Error%20Boundaries%20are%20a%20general,display%20a%20fallback%20UI%20instead.
const FallbackUI: React.FC = () => {
  const theme = useMantineTheme()

  const isMediumScreen = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`, true)

  return (
    <Box sx={(t) => ({ minHeight: t.other.root.minHeight })}>
      <Container size="sm" px={isMediumScreen ? 'lg' : 'sm'} pt="xl">
        <Image src={logo} alt="Stride Logo" />

        <Space h={theme.other.errorBoundary.logoMargin} />

        <Title
          order={2}
          sx={{
            lineHeight: theme.other.errorBoundary.titleLineHeight,
            fontSize: theme.other.errorBoundary.titleFontSize,
            letterSpacing: theme.other.errorBoundary.titleFontSpacing,
            fontFamily: theme.other.headingFontFamily
          }}>
          Meep morp. Zeep.
        </Title>
        <Space h="md" />

        <Text
          sx={{
            fontSize: theme.other.errorBoundary.descriptionFontSize,
            fontFamily: theme.other.headingFontFamily
          }}>
          Sorry, it seems that something didn't work quite as we expected. The Stride team has been notified and we'll
          have this fixed very soon.
        </Text>

        <Space h="lg" />

        <Button component="a" href="/">
          Go Back
        </Button>
      </Container>
    </Box>
  )
}

const RollbarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Provider instance={rollbar} config={rollbarConfig}>
      {/* @see https://stridelabs.atlassian.net/jira/software/projects/IN/boards/5?selectedIssue=IN-82&text=rollbar */}
      {/* @ts-ignore */}
      <ErrorBoundary fallbackUI={FallbackUI}>{children}</ErrorBoundary>
    </Provider>
  )
}

export { rollbar, RollbarProvider }
