import React, { useState, useContext, useRef } from 'react'
import { fatal } from '@/utils'
import { IconName } from '@/components'

interface BannerData {
  icon: IconName
  text: React.ReactNode
  url: string
}

interface TopProviderContextType {
  banner: BannerData | null
  setBanner: (banner: BannerData) => void
}

const Context = React.createContext<TopProviderContextType | null>(null)

const TopBannerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [banner, internalSetBanner] = useState<BannerData | null>(null)

  const timeoutRef = useRef<number>()

  const setBanner = (banner: BannerData) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    internalSetBanner(banner)

    timeoutRef.current = window.setTimeout(() => {
      internalSetBanner(null)
    }, 1000 * 30)
  }

  return <Context.Provider value={{ banner, setBanner }}>{children}</Context.Provider>
}

const useTopBanner = () => {
  const context = useContext(Context)

  if (!context) {
    throw fatal('You forgot to mount the TopBannerProvider')
  }

  return context
}

export { TopBannerProvider, useTopBanner }
