import React from 'react'
import { Text, TextProps, Sx, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

interface TextHeadingProps {
  sx?: Sx
  weight?: TextProps<'div'>['weight']
  align?: TextProps<'div'>['align']
  children: React.ReactNode
}

const TextHeading: React.FC<TextHeadingProps> = ({ sx, weight = 700, align, children }) => {
  const theme = useMantineTheme()

  const isMediumScreen = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`, true)

  return (
    <Text
      inline
      weight={weight}
      align={align}
      sx={(t) => ({
        fontSize: isMediumScreen ? t.other.textHeading.fontSizeDesktop : t.other.textHeading.fontSizeMobile,
        fontFamily: t.other.headingFontFamily,
        letterSpacing: t.other.textHeading.letterSpacing,
        ...(typeof sx === 'function' ? sx(t) : sx)
      })}>
      {children}
    </Text>
  )
}

export { TextHeading }
