import React from 'react'
import { SelectWalletModal } from './SelectWalletModal'
import { WalletConnectQRModal } from './WalletConnectQRModal'
import { MissingExtensionModal } from './MissingExtensionModal'

const GlobalWalletConnection = () => {
  return (
    <>
      <SelectWalletModal />
      <WalletConnectQRModal />
      <MissingExtensionModal />
    </>
  )
}

export { GlobalWalletConnection }
