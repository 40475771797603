import type { MsgTransfer } from 'cosmjs-types/ibc/applications/transfer/v1/tx'
import { Currency } from '@keplr-wallet/types'
import { TX_MSG } from './registry'
import {
  AccountCurrency,
  SelectedCoinDenom,
  SelectedCoinMinimalDenom,
  MsgRedeemStake,
  MsgLiquidStake,
  IBCMetaData,
  IBCLiquidityPoolMetaData,
  RedemptionMetaData,
  LiquidStakeMetaData,
  IBCWithdrawStTokenMetaData
} from './types'

const isSelectedCoinDenom = (str: string): str is SelectedCoinDenom => {
  return ['ATOM', 'SCRT', 'STRD', 'JUNO', 'OSMO', 'STARS', 'LUNA', 'EVMOS'].includes(str)
}

const isSelectedCoinMinimalDenom = (str: string): str is SelectedCoinMinimalDenom => {
  return ['uatom', 'uscrt', 'ustrd', 'ujuno', 'uosmo', 'ustars', 'uluna', 'aevmos'].includes(str)
}

const isAccountCurrency = (currency: Currency): currency is AccountCurrency => {
  return isSelectedCoinDenom(currency.coinDenom) && isSelectedCoinMinimalDenom(currency.coinMinimalDenom)
}

const isMsgTransfer = (message: any): message is MsgTransfer => {
  return message?.['@type'] === TX_MSG.MsgTransfer
}

const isMsgRedeemStake = (message: any): message is MsgRedeemStake => {
  return message?.['@type'] === TX_MSG.MsgRedeemStake
}

const isMsgLiquidStake = (message: any): message is MsgLiquidStake => {
  return message?.['@type'] === TX_MSG.MsgLiquidStake
}

const isIBCMetaData = (meta: any): meta is IBCMetaData => {
  return ['deposit', 'withdraw'].includes(meta.type)
}

const isIBCLiquidityPoolMetaData = (meta: any): meta is IBCLiquidityPoolMetaData => {
  return meta.type === 'ibc-lp'
}

const isIBCWithdrawStTokenMetaData = (meta: any): meta is IBCWithdrawStTokenMetaData => {
  return meta.type === 'ibc-withdraw-st-token'
}

const isRedemptionMetaData = (meta: any): meta is RedemptionMetaData => {
  return meta.type === 'unstaking'
}

const isLiquidStakeMetaData = (meta: any): meta is LiquidStakeMetaData => {
  return meta.type === 'stake'
}

export {
  isSelectedCoinDenom,
  isSelectedCoinMinimalDenom,
  isAccountCurrency,
  isMsgTransfer,
  isMsgLiquidStake,
  isMsgRedeemStake,
  isIBCMetaData,
  isIBCLiquidityPoolMetaData,
  isIBCWithdrawStTokenMetaData,
  isRedemptionMetaData,
  isLiquidStakeMetaData
}
