import { pickBy } from 'lodash'

// Unfortunately,
// Keep this file in-sync with .env.example
const ENV = {
  IBC_TIMEOUT_TESTING: process.env.NEXT_PUBLIC_IBC_TIMEOUT_TESTING,
  IBC_RETURN_LATER_MS: process.env.NEXT_PUBLIC_IBC_RETURN_LATER_MS,
  GAIA_RPC: process.env.NEXT_PUBLIC_GAIA_RPC,
  GAIA_REST: process.env.NEXT_PUBLIC_GAIA_REST,
  GAIA_CHAIN_ID: process.env.NEXT_PUBLIC_GAIA_CHAIN_ID,
  GAIA_CHAIN_NAME: process.env.NEXT_PUBLIC_GAIA_CHAIN_NAME,
  GAIA_DEPOSIT_CHANNEL: process.env.NEXT_PUBLIC_GAIA_DEPOSIT_CHANNEL,
  GAIA_WITHDRAW_CHANNEL: process.env.NEXT_PUBLIC_GAIA_WITHDRAW_CHANNEL,
  STARS_RPC: process.env.NEXT_PUBLIC_STARS_RPC,
  STARS_REST: process.env.NEXT_PUBLIC_STARS_REST,
  STARS_CHAIN_ID: process.env.NEXT_PUBLIC_STARS_CHAIN_ID,
  STARS_CHAIN_NAME: process.env.NEXT_PUBLIC_STARS_CHAIN_NAME,
  STARS_DEPOSIT_CHANNEL: process.env.NEXT_PUBLIC_STARS_DEPOSIT_CHANNEL,
  STARS_WITHDRAW_CHANNEL: process.env.NEXT_PUBLIC_STARS_WITHDRAW_CHANNEL,
  OSMO_RPC: process.env.NEXT_PUBLIC_OSMO_RPC,
  OSMO_REST: process.env.NEXT_PUBLIC_OSMO_REST,
  OSMO_CHAIN_ID: process.env.NEXT_PUBLIC_OSMO_CHAIN_ID,
  OSMO_CHAIN_NAME: process.env.NEXT_PUBLIC_OSMO_CHAIN_NAME,
  OSMO_DEPOSIT_CHANNEL: process.env.NEXT_PUBLIC_OSMO_DEPOSIT_CHANNEL,
  OSMO_WITHDRAW_CHANNEL: process.env.NEXT_PUBLIC_OSMO_WITHDRAW_CHANNEL,
  STRIDE_RPC: process.env.NEXT_PUBLIC_STRIDE_RPC,
  STRIDE_REST: process.env.NEXT_PUBLIC_STRIDE_REST,
  STRIDE_CHAIN_ID: process.env.NEXT_PUBLIC_STRIDE_CHAIN_ID,
  STRIDE_CHAIN_NAME: process.env.NEXT_PUBLIC_STRIDE_CHAIN_NAME,
  STRIDE_MINTSCAN_TX_URL: process.env.NEXT_PUBLIC_STRIDE_MINTSCAN_TX_URL,
  STRIDE_FAUCET_API_URL: process.env.NEXT_PUBLIC_STRIDE_FAUCET_API_URL,
  JUNO_RPC: process.env.NEXT_PUBLIC_JUNO_RPC,
  JUNO_REST: process.env.NEXT_PUBLIC_JUNO_REST,
  JUNO_CHAIN_ID: process.env.NEXT_PUBLIC_JUNO_CHAIN_ID,
  JUNO_CHAIN_NAME: process.env.NEXT_PUBLIC_JUNO_CHAIN_NAME,
  JUNO_DEPOSIT_CHANNEL: process.env.NEXT_PUBLIC_JUNO_DEPOSIT_CHANNEL,
  JUNO_WITHDRAW_CHANNEL: process.env.NEXT_PUBLIC_JUNO_WITHDRAW_CHANNEL,
  LUNA_RPC: process.env.NEXT_PUBLIC_LUNA_RPC,
  LUNA_REST: process.env.NEXT_PUBLIC_LUNA_REST,
  LUNA_CHAIN_ID: process.env.NEXT_PUBLIC_LUNA_CHAIN_ID,
  LUNA_CHAIN_NAME: process.env.NEXT_PUBLIC_LUNA_CHAIN_NAME,
  LUNA_DEPOSIT_CHANNEL: process.env.NEXT_PUBLIC_LUNA_DEPOSIT_CHANNEL,
  LUNA_WITHDRAW_CHANNEL: process.env.NEXT_PUBLIC_LUNA_WITHDRAW_CHANNEL,
  EVMOS_RPC: process.env.NEXT_PUBLIC_EVMOS_RPC,
  EVMOS_REST: process.env.NEXT_PUBLIC_EVMOS_REST,
  EVMOS_CHAIN_ID: process.env.NEXT_PUBLIC_EVMOS_CHAIN_ID,
  EVMOS_CHAIN_NAME: process.env.NEXT_PUBLIC_EVMOS_CHAIN_NAME,
  EVMOS_DEPOSIT_CHANNEL: process.env.NEXT_PUBLIC_EVMOS_DEPOSIT_CHANNEL,
  EVMOS_WITHDRAW_CHANNEL: process.env.NEXT_PUBLIC_EVMOS_WITHDRAW_CHANNEL,
  SCRT_RPC: process.env.NEXT_PUBLIC_SCRT_RPC,
  SCRT_REST: process.env.NEXT_PUBLIC_SCRT_REST,
  SCRT_CHAIN_ID: process.env.NEXT_PUBLIC_SCRT_CHAIN_ID,
  SCRT_CHAIN_NAME: process.env.NEXT_PUBLIC_SCRT_CHAIN_NAME,
  SCRT_DEPOSIT_CHANNEL: process.env.NEXT_PUBLIC_SCRT_DEPOSIT_CHANNEL,
  SCRT_WITHDRAW_CHANNEL: process.env.NEXT_PUBLIC_SCRT_WITHDRAW_CHANNEL,
  UPSTASH_REDIS_MAIN_URL: process.env.NEXT_PUBLIC_UPSTASH_REDIS_MAIN_URL,
  UPSTASH_REDIS_MAIN_TOKEN: process.env.NEXT_PUBLIC_UPSTASH_REDIS_MAIN_TOKEN,
  UPSTASH_REDIS_AIRDROP_URL: process.env.NEXT_PUBLIC_UPSTASH_REDIS_AIRDROP_URL,
  UPSTASH_REDIS_AIRDROP_TOKEN: process.env.NEXT_PUBLIC_UPSTASH_REDIS_AIRDROP_TOKEN,
  UPSTASH_REDIS_ANNOUNCEMENT_URL: process.env.NEXT_PUBLIC_UPSTASH_REDIS_ANNOUNCEMENT_URL,
  UPSTASH_REDIS_ANNOUNCEMENT_TOKEN: process.env.NEXT_PUBLIC_UPSTASH_REDIS_ANNOUNCEMENT_TOKEN,
  ROLLBAR_ENV: process.env.NEXT_PUBLIC_ROLLBAR_ENV,
  ROLLBAR_TOKEN: process.env.NEXT_PUBLIC_ROLLBAR_TOKEN
}

const object = pickBy(ENV, Boolean)

// Shortcut for getting next environment keys
const env = (key: keyof typeof ENV, defaultValue?: string): string => {
  return object[key] ?? defaultValue ?? ''
}

export { env }
