import { useMemo } from 'react'
import { config } from '@/config'
import logoLight from '@/images/logo-light.svg'
import { Anchor, Box, Container, Group, Space, Stack, Text, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import Link from 'next/link'

const Footer = () => {
  const theme = useMantineTheme()

  const isLargeScreen = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`, true)

  const currentYear = useMemo(() => {
    return new Date().getFullYear()
  }, [])

  return (
    <Box>
      <Space h={160} />

      <Box sx={(t) => ({ background: t.other.footer.bg, color: t.white, fontFamily: t.other.footer.fontFamily })}>
        <Container size="xl" px={32}>
          <Box
            sx={(t) => ({
              paddingTop: isLargeScreen
                ? t.other.footer.contentPaddingTopDesktop
                : t.other.footer.contentPaddingTopMobile,
              paddingBottom: isLargeScreen
                ? t.other.footer.contentPaddingBottomDesktop
                : t.other.footer.contentPaddingBottomMobile
            })}>
            <Group
              align={isLargeScreen ? 'top' : undefined}
              position={isLargeScreen ? 'apart' : 'center'}
              direction={isLargeScreen ? 'row' : 'column'}
              spacing="xl">
              <Box pb={isLargeScreen ? 0 : 'md'}>
                <Anchor href={config.links.website} target="_blank" sx={{ display: 'inline-block' }}>
                  <img
                    src={logoLight.src}
                    style={{
                      marginTop: theme.other.footer.logoMarginTop,
                      maxHeight: theme.other.footer.logoMaxHeight,
                      opacity: theme.other.footer.logoOpacity
                    }}
                  />
                </Anchor>
              </Box>

              <Box>
                <Text
                  align={isLargeScreen ? 'left' : 'center'}
                  weight={700}
                  size={isLargeScreen ? 'xl' : 'md'}
                  sx={(t) => ({ fontFamily: t.other.footer.fontFamily, lineHeight: `24px` })}>
                  Technology
                </Text>

                <Space h={24} />

                <Stack spacing="sm">
                  <Anchor
                    href={config.links.github}
                    target="_blank"
                    underline={false}
                    align={isLargeScreen ? 'left' : 'center'}
                    size="md"
                    sx={(theme) => ({
                      color: theme.white,
                      fontFamily: theme.other.footer.fontFamily,
                      '&:hover': { color: theme.colors.pink[6], transition: theme.other.footer.linkTransition }
                    })}>
                    GitHub
                  </Anchor>

                  <Anchor
                    href={config.links.docs}
                    target="_blank"
                    underline={false}
                    align={isLargeScreen ? 'left' : 'center'}
                    size="md"
                    sx={(theme) => ({
                      color: theme.white,
                      fontFamily: theme.other.footer.fontFamily,
                      '&:hover': { color: theme.colors.pink[6], transition: theme.other.footer.linkTransition }
                    })}>
                    Documentation
                  </Anchor>

                  <Anchor
                    href={config.links.testnet}
                    target="_blank"
                    underline={false}
                    align={isLargeScreen ? 'left' : 'center'}
                    size="md"
                    sx={(theme) => ({
                      color: theme.white,
                      fontFamily: theme.other.footer.fontFamily,
                      '&:hover': { color: theme.colors.pink[6], transition: theme.other.footer.linkTransition }
                    })}>
                    Testnet
                  </Anchor>
                </Stack>
              </Box>

              <Box>
                <Text
                  align={isLargeScreen ? 'left' : 'center'}
                  weight={700}
                  size={isLargeScreen ? 'xl' : 'md'}
                  sx={(t) => ({ fontFamily: t.other.footer.fontFamily, lineHeight: `24px` })}>
                  Community
                </Text>

                <Space h={24} />

                <Stack spacing={16}>
                  <Anchor
                    href={config.links.twitter}
                    target="_blank"
                    underline={false}
                    align={isLargeScreen ? 'left' : 'center'}
                    size="md"
                    sx={(theme) => ({
                      color: theme.white,
                      fontFamily: theme.other.footer.fontFamily,
                      '&:hover': { color: theme.colors.pink[6], transition: theme.other.footer.linkTransition }
                    })}>
                    Twitter
                  </Anchor>

                  <Anchor
                    href={config.links.discord}
                    target="_blank"
                    underline={false}
                    align={isLargeScreen ? 'left' : 'center'}
                    size="md"
                    sx={(theme) => ({
                      color: theme.white,
                      fontFamily: theme.other.footer.fontFamily,
                      '&:hover': { color: theme.colors.pink[6], transition: theme.other.footer.linkTransition }
                    })}>
                    Discord
                  </Anchor>
                </Stack>
              </Box>

              <Box>
                <Text
                  align={isLargeScreen ? 'left' : 'center'}
                  weight={700}
                  size={isLargeScreen ? 'xl' : 'md'}
                  sx={(t) => ({ fontFamily: t.other.footer.fontFamily, lineHeight: `24px` })}>
                  More
                </Text>

                <Space h={24} />

                <Stack spacing={16}>
                  <Anchor
                    href={config.links.email}
                    underline={false}
                    align={isLargeScreen ? 'left' : 'center'}
                    size="md"
                    sx={(theme) => ({
                      color: theme.white,
                      fontFamily: theme.other.footer.fontFamily,
                      '&:hover': { color: theme.colors.pink[6], transition: theme.other.footer.linkTransition }
                    })}>
                    Contact Us
                  </Anchor>

                  <Anchor
                    href={config.links.faq}
                    underline={false}
                    align={isLargeScreen ? 'left' : 'center'}
                    size="md"
                    sx={(theme) => ({
                      color: theme.white,
                      fontFamily: theme.other.footer.fontFamily,
                      '&:hover': { color: theme.colors.pink[6], transition: theme.other.footer.linkTransition }
                    })}>
                    FAQ
                  </Anchor>

                  <Link href="/tos" passHref>
                    <Anchor
                      underline={false}
                      align={isLargeScreen ? 'left' : 'center'}
                      size="md"
                      sx={(theme) => ({
                        color: theme.white,
                        fontFamily: theme.other.footer.fontFamily,
                        '&:hover': { color: theme.colors.pink[6], transition: theme.other.footer.linkTransition }
                      })}>
                      Terms of Service
                    </Anchor>
                  </Link>
                </Stack>
              </Box>
            </Group>
          </Box>

          <Box
            sx={(t) => ({
              paddingTop: isLargeScreen ? t.other.footer.copyrightPaddingDesktop : t.spacing.md,
              paddingBottom: isLargeScreen ? t.other.footer.copyrightPaddingDesktop : t.spacing.md,
              borderTop: `1px solid ${t.other.footer.dividerColor}`
            })}>
            <Text
              size="md"
              sx={(t) => ({ color: t.other.footer.copyrightColor, fontFamily: t.other.footer.fontFamily })}>
              &copy; {currentYear} Stride Labs
            </Text>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export { Footer }
