import React from 'react'
import { Group, Stack, Text, MantineNumberSize } from '@mantine/core'
import { InfoBeacon, InfoBeaconContainer } from '@/components/InfoBeacon'

interface DataListProps {
  children: React.ReactNode
  spacing?: MantineNumberSize
}

const DataList: React.FC<DataListProps> = ({ children, spacing = 'md' }) => {
  return <Stack spacing={spacing}>{children}</Stack>
}

interface DataListItemProps {
  label: React.ReactNode
  bold?: boolean
  info?: string
  infoWidth?: number
  value: React.ReactNode
}

const DataListItem: React.FC<DataListItemProps> = ({ label, bold = true, value, info, infoWidth }) => {
  return (
    <Group position="apart">
      <InfoBeaconContainer>
        <Text sx={(t) => ({ color: t.colors.gray[7] })} weight={bold ? 600 : 400}>
          {label}
        </Text>

        {info && <InfoBeacon content={info} sx={(t) => ({ color: t.colors.gray[6] })} width={infoWidth} />}
      </InfoBeaconContainer>

      <Text sx={(t) => ({ color: t.colors.gray[7] })}>{value}</Text>
    </Group>
  )
}

export { DataList, DataListItem }
