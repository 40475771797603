import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { useAtom } from 'jotai'
import { useMutation } from 'react-query'
import { CHAIN_INFO_LIST } from '@/config'
import { fatal } from '@/utils'
import { selectedAccountAtom, strideAccountAtom } from '../atoms'
import { TX_MSG } from '../registry'
import { convertDenomToMicroDenom, cleanMicroDenom } from '../utils'

const useSignUnstakeTokens = () => {
  const [selectedAccount] = useAtom(selectedAccountAtom)

  const [strideAccount] = useAtom(strideAccountAtom)

  return useMutation('wallet/sign-unstake-tokens', async (amount: number): Promise<TxRaw> => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('You are unable to redeem staked tokens without connecting your wallet.')
    }

    const amountInMicroDenom = convertDenomToMicroDenom(amount, selectedAccount.currency.coinMinimalDenom)

    const msgRedeemStake = {
      typeUrl: TX_MSG.MsgRedeemStake,
      value: {
        creator: strideAccount.key.bech32Address,
        amount: cleanMicroDenom(amountInMicroDenom),
        hostZone: CHAIN_INFO_LIST[selectedAccount.currency.coinDenom].chainId,
        receiver: selectedAccount.key.bech32Address
      }
    }

    const fee = {
      amount: [{ amount: '0', denom: strideAccount.currency.coinMinimalDenom }],
      gas: String(convertDenomToMicroDenom(1))
    }

    return await strideAccount.client.sign(strideAccount.key.bech32Address, [msgRedeemStake], fee, '')
  })
}

export { useSignUnstakeTokens }
