import { useAtom } from 'jotai'
import { fatal } from '@/utils'
import { convertMicroDenomToDenom } from '../utils'
import { accountsAtom } from '../atoms'
import { Account } from '../types'

const useUpdateAccountBalances = () => {
  const [, setAccounts] = useAtom(accountsAtom)

  const updateAccountBalances = async (account: Account) => {
    const accountBalances = await account.client.getAllBalances(account.key.bech32Address).catch((e) => {
      throw new UpdateAccountBalanceError(e)
    })

    const accountCoins = accountBalances.map((coin) => {
      return {
        denom: coin.denom,
        // @TODO: Let's keep micro denoms as is and convert them in our components' render body instead.
        // This made sense initially, but since the introduction of EVMOS, it may make more sense
        // to maintain micro denom values while denom values are display-only. This might be a lot of work
        // so let's re-evaluate this once we start working on this.
        amount: String(convertMicroDenomToDenom(coin.amount, coin.denom))
      }
    })

    setAccounts((draft) => {
      const accountDraft = draft.find((a) => {
        return a.currency.coinDenom === account.currency.coinDenom
      })

      if (!accountDraft) {
        throw fatal(
          'Your Stride account and selected account could not be found while fetching your updated wallet coins.'
        )
      }

      const accountUpdatedCoin = accountCoins.find((coin) => {
        return account.coin.denom === coin.denom
      })

      accountDraft.coin.amount = accountUpdatedCoin ? accountUpdatedCoin.amount : '0'

      accountDraft.coins = accountCoins
    })
  }

  return { updateAccountBalances }
}

export class UpdateAccountBalanceError extends Error {}

export { useUpdateAccountBalances }
