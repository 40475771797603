import React, { useState } from 'react'
import {
  Anchor,
  Box,
  Divider,
  Group,
  Text,
  Collapse,
  Space,
  UnstyledButton,
  useMantineTheme,
  MantineColor
} from '@mantine/core'
import { DataListItem, Icon, IconName, StaticActionIcon } from '@/components'

const CollapsibleStatsList: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const length = React.Children.count(children)

  return (
    <>
      {React.Children.map(children, (child, i) => (
        <React.Fragment key={i}>
          {child}
          {i !== length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </>
  )
}

interface CollapsibleStatsItem {
  icon: IconName
  color?: MantineColor
  variant?: 'filled' | 'light'
  round?: boolean
  label: string
  value?: React.ReactNode
  description?: React.ReactNode
  data?: {
    label: React.ReactNode
    value: React.ReactNode
  }[]
}

const CollapsibleStatsItem: React.FC<CollapsibleStatsItem> = ({
  icon,
  variant = 'light',
  color = 'cyan',
  round = true,
  label,
  value,
  description,
  data
}) => {
  const t = useMantineTheme()

  const iconSize = 'lg'

  const caretSize = 'xs'

  const contentPaddingLeft = t.other.components.StaticActionIcon.sizes[iconSize] + t.spacing.sm

  const contentPaddingRight = t.spacing.md + t.fontSizes[caretSize]

  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen((isOpen) => !isOpen)
  }

  return (
    <Box pt="sm" pb="sm">
      <UnstyledButton onClick={handleToggle} sx={{ width: '100%' }}>
        <Group align="center" position="apart">
          <Group align="center" spacing="sm">
            <StaticActionIcon icon={icon} variant={variant} color={color} size={iconSize} round={round} />

            <Text weight={600} inline sx={(t) => ({ color: t.colors.gray[9] })}>
              {label}
            </Text>
          </Group>

          <Group align="center" spacing="md">
            {Boolean(label) && (
              <Text weight={600} inline sx={(t) => ({ color: t.colors.gray[9] })}>
                {value}
              </Text>
            )}

            <Box sx={{ transform: isOpen ? '' : 'rotate(180deg)' }}>
              <Icon name="caretDown" size="xs" />
            </Box>
          </Group>
        </Group>
      </UnstyledButton>

      <Collapse in={isOpen}>
        <Box pt="xs" pl={contentPaddingLeft} pr={contentPaddingRight}>
          <Text sx={(t) => ({ color: t.colors.gray[7] })}>{description}</Text>

          {Array.isArray(data) && Boolean(data.length) && (
            <>
              <Space h="md" />
              {data.map((d, i) => (
                <DataListItem label={d.label} value={d.value} bold={false} key={i} />
              ))}
            </>
          )}
        </Box>
      </Collapse>
    </Box>
  )
}

interface CollapsibleStatsItemAnchorProps {
  children: React.ReactNode
  target?: string
  href?: string
}

const CollapsibleStatsItemAnchor: React.FC<CollapsibleStatsItemAnchorProps> = ({ href, target, children }) => {
  return (
    <Anchor href={href} target={target} underline sx={{ color: 'currentColor' }}>
      {children}
    </Anchor>
  )
}

export { CollapsibleStatsList, CollapsibleStatsItem, CollapsibleStatsItemAnchor }
