import React from 'react'
import { useAtom } from 'jotai'
import { MantineProvider as OriginalMantineProvider } from '@mantine/core'
import { colorSchemeAtom } from '@/atoms/ColorScheme'
import { theme, themeDefaultStyles, themeDefaultProps } from './theme'

const MantineProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [colorScheme] = useAtom(colorSchemeAtom)

  return (
    <OriginalMantineProvider
      theme={{ ...theme, colorScheme }}
      styles={themeDefaultStyles}
      defaultProps={themeDefaultProps}
      withGlobalStyles
      withNormalizeCSS>
      {children}
    </OriginalMantineProvider>
  )
}

export { MantineProvider }
