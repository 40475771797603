import { useEffect, useState } from 'react'
import { useAtom, useAtomValue } from 'jotai'
import { useQuery } from 'react-query'
import { SigningStargateClient } from '@cosmjs/stargate'
import { getSigningStrideClientOptions } from 'stridejs'
import { accountParser } from '../parser'
import { notify } from '@/atoms/Notifications'
import { defaultConnectionTypeAtom, useWalletConnection, walletKeyChangeEvent } from '@/atoms/WalletConnection'
import { fatal } from '@/utils'
import { useWindowListener } from '@/hooks'
import { CHAIN_INFO_LIST } from '@/config'
import { accountsAtom, strideAccountAtom, selectedCoinDenomAtom } from '../atoms'
import { convertMicroDenomToDenom } from '../utils'
import { Account, SelectedCoinDenom } from '../types'
import { rollbar } from '@/rollbar'
import { isAccountCurrency } from '../type-guards'

const useConnectWallet = () => {
  const { getWallet, clearLastUsedWallet } = useWalletConnection()

  const [accounts, setAccounts] = useAtom(accountsAtom)

  const [strideAccount] = useAtom(strideAccountAtom)

  const [selectedCoinDenom] = useAtom(selectedCoinDenomAtom)

  // Flag used to trigger wallet data fetching when the user clicks the "Connect Wallet" button
  const [isConnectButtonClicked, setIsConnectButtonClicked] = useState(false)

  const defaultConnectionType = useAtomValue(defaultConnectionTypeAtom)

  const [isReconnectingWallet, setIsReconnectingWallet] = useState(false)

  const connectWallet = () => {
    setIsConnectButtonClicked(true)
  }

  const disconnectWallet = () => {
    setIsConnectButtonClicked(false)
    clearLastUsedWallet()
    setAccounts([])
    const payload = { person: { id: null } }
    // @see https://github.com/rollbar/rollbar.js/issues/1058
    // @ts-ignore
    rollbar.configure({ payload })
  }

  const reconnectWallet = () => {
    setIsConnectButtonClicked(false)
    setAccounts([])
    setIsReconnectingWallet(true)
  }

  // We'll first simulate a disconnection then only reconnect which does two things:
  // - It gives our user a visual feedback that they are reconnecting.
  // - Forces our query hook to do a refetch with the user's freshly selected wallet.
  useEffect(() => {
    if (!isReconnectingWallet) return
    connectWallet()
    setIsReconnectingWallet(false)
  }, [isReconnectingWallet])

  const getWalletData = async (denom: SelectedCoinDenom): Promise<Account> => {
    const { walletInfo, wallet } = await getWallet()

    const chainInfo = CHAIN_INFO_LIST[denom]

    // So far, only Keplr has an implementation suggestChain
    if (walletInfo.id === 'keplr-wallet-extension') {
      // Safely trigger password prompt (if Keplr is logged out) because experimentalSuggestChain
      // does not throw when user closes the prompt.
      // We'll attempt to trigger prompt. If it fails because user closed the prompt, we'll throw.
      // It should be safe to run this:
      // [chain id unsuggested]: We catch it and silence the error
      // [wallet enabled previously]: Keplr auto-resolves already enabled wallets
      // @see: https://github.com/chainapsis/keplr-wallet/issues/464
      await wallet.enable([chainInfo.chainId]).catch((e: Error) => {
        if (e.message !== 'Request rejected') return
        // If we got here, it means user closed the prompt
        notify.error(`Unable to connect to Keplr.`)
        throw e
      })

      await wallet.experimentalSuggestChain(chainInfo).catch((e: Error) => {
        notify.error(`An error occured while suggesting ${chainInfo.chainName} your wallet. Please try again.`)
        throw e
      })
    }

    await wallet.enable([chainInfo.chainId]).catch((e: Error) => {
      notify.error(`An error occured while enabling ${chainInfo.chainId} on your wallet. Please try again.`)
      throw e
    })

    const signer = await wallet.getOfflineSignerAuto(chainInfo.chainId).catch((e: Error) => {
      notify.error(
        `An error occured while getting the offline signer for ${chainInfo.chainName} (${chainInfo.chainId}). Please try again.`
      )
      throw e
    })

    // We need this as stridejs.getSigningStrideClient does not contain default
    // amino types for messages such as TX_MSG.MsgTransfer. Let's get rid of this in the future
    // once we've fixed this directly from stride.js
    // @TODO https://github.com/Stride-Labs/interface/issues/81
    const stargate = await SigningStargateClient.connectWithSigner(chainInfo.rpc, signer, {
      accountParser: accountParser
    }).catch((e) => {
      notify.error(`An error occured while connecting to your wallet. Please refresh the page.`)
      throw e
    })

    const { registry, aminoTypes } = getSigningStrideClientOptions()

    const client = await SigningStargateClient.connectWithSigner(chainInfo.rpc, signer, {
      // @ts-ignore
      registry,
      aminoTypes,
      accountParser: accountParser
    })

    const key = await wallet.getKey(chainInfo.chainId).catch((e: Error) => {
      notify.error(`An error occured while connecting to your ${chainInfo.chainName} wallet. Please refresh the page.`)
      throw e
    })

    const coins = (
      await client.getAllBalances(key.bech32Address).catch((e: Error) => {
        notify.error(
          `An error occured while connecting to your ${chainInfo.chainName} wallet. Please refresh the page.`
        )
        throw e
      })
    ).map((coin) => {
      return {
        denom: coin.denom,
        // @TODO: Let's keep micro denoms as is and convert them in our components' render body instead.
        // This made sense initially, but since the introduction of EVMOS, it may make more sense
        // to maintain micro denom values while denom values are display-only. This might be a lot of work
        // so let's re-evaluate this once we start working on this.
        amount: String(convertMicroDenomToDenom(coin.amount, coin.denom))
      }
    })

    // @TODO: Let's use stakeCurrency instead, maybe?
    const currency = chainInfo.currencies[0]

    if (!isAccountCurrency(currency)) {
      throw fatal(`Account coin denom (${currency.coinDenom}) is invalid`)
    }

    const coin = coins.find((coin) => {
      return coin.denom === currency.coinMinimalDenom
    }) || { denom: currency.coinMinimalDenom, amount: '0' }

    const account: Account = {
      key,
      coin,
      currency,
      coins,
      client,
      stargate,
      signer
    }

    return account
  }

  const isAccountFetched = (account: Account) => {
    return accounts.find((a) => a.key.bech32Address === account.key.bech32Address) != null
  }

  const isStrideAccountFetchingEnabled = () => {
    // If we're attempting to reconnect, we want to strictly set this to false so we can refetch.
    if (isReconnectingWallet) {
      return false
    }

    // Wallet date fetching is triggered through button click or if the user has a "previous session".
    // defaultConnectionType is a simple state persisted in the local-storage.
    return isConnectButtonClicked || Boolean(defaultConnectionType)
  }

  // @TODO: Handle error from the components if fetching fails
  const { isFetching: isConnectingWallet } = useQuery(['wallet-accounts', 'STRD'], () => getWalletData('STRD'), {
    onSuccess: (account) => {
      setAccounts((draft) => {
        if (isAccountFetched(account)) return
        draft.push(account)
      })

      setIsConnectButtonClicked(false)
    },
    onError: () => {
      clearLastUsedWallet()
      setIsConnectButtonClicked(false)
    },
    enabled: isStrideAccountFetchingEnabled(),
    retry: false
  })

  // This is definitely not ideal, but we need to fetch their osmosis address in order to make liquidity pools to work.
  // Let's refactor this in the future once we start working on the rearchitecture where we fetch all their chain data up front.
  // @TODO: Handle error from the components if fetching fails
  const { isFetching: isFetchingOsmosisAccount } = useQuery(
    ['wallet-accounts', 'OSMO', strideAccount],
    () => getWalletData('OSMO'),
    {
      onSuccess: (account) => {
        setAccounts((draft) => {
          if (isAccountFetched(account)) return
          draft.push(account)
        })
      },
      // After we load their stride account only do we load their selected account's wallet
      enabled: Boolean(strideAccount),
      retry: false
    }
  )

  // @TODO: Handle error from the components if fetching fails
  const { isFetching: isFetchingSelectedAccount } = useQuery(
    ['wallet-accounts', selectedCoinDenom],
    () => getWalletData(selectedCoinDenom),
    {
      onSuccess: (account) => {
        setAccounts((draft) => {
          if (isAccountFetched(account)) return
          draft.push(account)
        })

        const payload = {
          person: { id: account.key.bech32Address }
        }
        rollbar.configure({ payload })
      },
      // After we load their stride account only do we load their selected account's wallet
      enabled: Boolean(strideAccount),
      retry: false
    }
  )

  const keyChangeEvent = defaultConnectionType && strideAccount ? walletKeyChangeEvent[defaultConnectionType] : null

  useWindowListener(keyChangeEvent, () => {
    reconnectWallet()
  })

  return {
    connectWallet,
    disconnectWallet,
    isConnectingWallet,
    isFetchingSelectedAccount,
    isFetchingOsmosisAccount
  }
}

export { useConnectWallet }
