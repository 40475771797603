import { useMutation, useQueryClient } from 'react-query'
import { useAtomValue } from 'jotai'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { notify } from '@/atoms/Notifications'
import { fatal, disregard } from '@/utils'
import { queryKeys } from '@/query-keys'
import { assertIsDeliverTxSuccess } from '../utils'
import { selectedAccountAtom, strideAccountAtom } from '../atoms'
import { useUpdateAccountBalances } from './useUpdateAccountBalances'

export interface BroadcastStakeTokenParameters {
  raw: TxRaw
}

// Broadcasts MsgLiquidStake
// If staking completes, we'll requery the transaction list.
// The requery will take care of the minimized stake tx.
const useBroadcastStakeToken = () => {
  const selectedAccount = useAtomValue(selectedAccountAtom)

  const strideAccount = useAtomValue(strideAccountAtom)

  const client = useQueryClient()

  const { updateAccountBalances } = useUpdateAccountBalances()

  return useMutation('wallet/broadcast-stake-token', async ({ raw }: BroadcastStakeTokenParameters) => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('You are unable to stake without connecting your wallet.')
    }

    const bytes = TxRaw.encode(raw).finish()

    const tx = await strideAccount.client.broadcastTx(bytes)

    await client.invalidateQueries(queryKeys.transactionHistoryBase)

    assertIsDeliverTxSuccess(tx)

    try {
      await updateAccountBalances(strideAccount)
    } catch (e) {
      notify.error(`An error occured while fetching your updated account balance. Please refresh the page.`)
      disregard(e)
    }

    return tx
  })
}

export { useBroadcastStakeToken }
