import { atom, useAtom } from 'jotai'
import { ColorSchemeProvider as OriginalColorSchemeProvider } from '@mantine/core'

const colorSchemeAtom = atom<'light' | 'dark'>('light')

const toggleColorSchemeAtom = atom(
  (get) => get(colorSchemeAtom),
  (get, set, nextValue) => {
    set(colorSchemeAtom, nextValue || get(colorSchemeAtom) === 'light' ? 'dark' : 'light')
  }
)

const ColorSchemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [colorScheme, toggleColorScheme] = useAtom(toggleColorSchemeAtom)
  return (
    <OriginalColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      {children}
    </OriginalColorSchemeProvider>
  )
}

export { colorSchemeAtom, toggleColorSchemeAtom, ColorSchemeProvider }
