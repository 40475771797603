import { Bech32Address } from '@keplr-wallet/cosmos'
import { ChainInfo, Currency } from '@keplr-wallet/types'
import { env } from '@/utils/env'
import { SelectedCoinDenom } from '@/atoms/Wallet/types'

export const STRIDE_CURRENCY: Currency = {
  coinDenom: 'STRD',
  coinMinimalDenom: 'ustrd',
  coinDecimals: 6
}

const EDGE_URL = "https://edge-staging.stride.zone"

export const STRIDE_EXTRA_CURRENCIES: Currency[] = [
  {
    coinDenom: 'ATOM',
    coinMinimalDenom: 'uatom',
    coinDecimals: 6
  },
  {
    coinDenom: 'JUNO',
    coinMinimalDenom: 'ujuno',
    coinDecimals: 6
  },
  {
    coinDenom: 'SCRT',
    coinMinimalDenom: 'uscrt',
    coinDecimals: 6
  }
]

export const STRIDE_CHAIN_INFO: ChainInfo = {
  rpc: env('STRIDE_RPC', 'https://stride-fleet.main.stridenet.co/'),
  rest: env('STRIDE_REST', 'https://stride-fleet.main.stridenet.co/api'),
  chainId: env('STRIDE_CHAIN_ID', 'stride-1'),
  chainName: env('STRIDE_CHAIN_NAME', 'Stride'),
  stakeCurrency: STRIDE_CURRENCY,
  bip44: {
    coinType: 118
  },
  bech32Config: {
    bech32PrefixAccAddr: 'stride',
    bech32PrefixAccPub: 'stridepub',
    bech32PrefixValAddr: 'stridevaloper',
    bech32PrefixValPub: 'stridevaloperpub',
    bech32PrefixConsAddr: 'stridevalcons',
    bech32PrefixConsPub: 'stridevalconspub'
  },
  currencies: [STRIDE_CURRENCY, ...STRIDE_EXTRA_CURRENCIES],
  feeCurrencies: [STRIDE_CURRENCY, ...STRIDE_EXTRA_CURRENCIES],
  coinType: 118,
  features: ['stargate', 'ibc-transfer', 'ibc-go']
}

export const GAIA_CHAIN_INFO: ChainInfo = {
  rpc: env('GAIA_RPC', EDGE_URL.concat('/api/cosmos/rpc')),
  rest: env('GAIA_REST', EDGE_URL.concat('/api/cosmos/lcd')),
  chainId: env('GAIA_CHAIN_ID', 'cosmoshub-4'),
  chainName: env('GAIA_CHAIN_NAME', 'Cosmos Hub'),
  bip44: {
    coinType: 118
  },
  bech32Config: Bech32Address.defaultBech32Config('cosmos'),
  stakeCurrency: {
    coinDenom: 'ATOM',
    coinMinimalDenom: 'uatom',
    coinDecimals: 6,
    coinGeckoId: 'cosmos'
    // coinImageUrl: '/tokens/atom.svg'
  },
  currencies: [
    {
      coinDenom: 'ATOM',
      coinMinimalDenom: 'uatom',
      coinDecimals: 6,
      coinGeckoId: 'cosmos'
      // coinImageUrl: '/tokens/atom.svg'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'ATOM',
      coinMinimalDenom: 'uatom',
      coinDecimals: 6,
      coinGeckoId: 'cosmos'
      // coinImageUrl: '/tokens/atom.svg'
    }
  ],
  features: ['stargate', 'ibc-transfer', 'no-legacy-stdTx', 'ibc-go']
}

export const OSMO_CHAIN_INFO: ChainInfo = {
  rpc: env('OSMO_RPC', EDGE_URL.concat('/api/osmosis/rpc')),
  rest: env('OSMO_REST', EDGE_URL.concat('/api/osmosis/lcd')),
  chainId: env('OSMO_CHAIN_ID', 'osmosis-1'),
  chainName: env('OSMO_CHAIN_NAME', 'Osmosis'),
  bip44: {
    coinType: 118
  },
  bech32Config: Bech32Address.defaultBech32Config('osmo'),
  stakeCurrency: {
    coinDenom: 'OSMO',
    coinMinimalDenom: 'uosmo',
    coinDecimals: 6,
    coinGeckoId: 'osmo'
    // coinImageUrl: '/tokens/atom.svg'
  },
  currencies: [
    {
      coinDenom: 'OSMO',
      coinMinimalDenom: 'uosmo',
      coinDecimals: 6,
      coinGeckoId: 'osmo'
      // coinImageUrl: '/tokens/atom.svg'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'OSMO',
      coinMinimalDenom: 'uosmo',
      coinDecimals: 6,
      coinGeckoId: 'osmo'
      // coinImageUrl: '/tokens/atom.svg'
    }
  ],
  features: ['stargate', 'ibc-transfer', 'no-legacy-stdTx', 'ibc-go']
}

export const STARS_CHAIN_INFO: ChainInfo = {
  rpc: env('STARS_RPC', EDGE_URL.concat('/api/stargaze/rpc')),
  rest: env('STARS_REST', EDGE_URL.concat('/api/stargaze/lcd')),
  chainId: env('STARS_CHAIN_ID', 'stargaze-1'),
  chainName: env('STARS_CHAIN_NAME', 'Stargaze'),
  bip44: {
    coinType: 118
  },
  bech32Config: Bech32Address.defaultBech32Config('stars'),
  stakeCurrency: {
    coinDenom: 'STARS',
    coinMinimalDenom: 'ustars',
    coinDecimals: 6,
    coinGeckoId: 'stars'
    // coinImageUrl: '/tokens/atom.svg'
  },
  currencies: [
    {
      coinDenom: 'STARS',
      coinMinimalDenom: 'ustars',
      coinDecimals: 6,
      coinGeckoId: 'stars'
      // coinImageUrl: '/tokens/atom.svg'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'STARS',
      coinMinimalDenom: 'ustars',
      coinDecimals: 6,
      coinGeckoId: 'stars'
      // coinImageUrl: '/tokens/atom.svg'
    }
  ]
}

export const SCRT_CHAIN_INFO: ChainInfo = {
  rpc: env('SCRT_RPC', 'https://rpc-secret.keplr.app'),
  rest: env('SCRT_REST', 'https://lcd-secret.keplr.app'),
  chainId: env('SCRT_CHAIN_ID', 'secret-4'),
  chainName: env('SCRT_CHAIN_NAME', 'Secret Network'),
  bip44: {
    coinType: 529
  },
  bech32Config: Bech32Address.defaultBech32Config('secret'),
  stakeCurrency: {
    coinDenom: 'SCRT',
    coinMinimalDenom: 'uscrt',
    coinDecimals: 6,
    coinGeckoId: 'secret',
    coinImageUrl: '/tokens/scrt.svg'
  },
  currencies: [
    {
      coinDenom: 'SCRT',
      coinMinimalDenom: 'uscrt',
      coinDecimals: 6,
      coinGeckoId: 'secret',
      coinImageUrl: '/tokens/scrt.svg'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'SCRT',
      coinMinimalDenom: 'uscrt',
      coinDecimals: 6,
      coinGeckoId: 'secret',
      coinImageUrl: '/tokens/scrt.svg'
    }
  ],
  features: ['stargate', 'ibc-transfer', 'no-legacy-stdTx']
}

export const JUNO_CHAIN_INFO: ChainInfo = {
  rpc: env('JUNO_RPC', EDGE_URL.concat('/api/juno/rpc')),
  rest: env('JUNO_REST', EDGE_URL.concat('/api/juno/lcd')),
  chainId: env('JUNO_CHAIN_ID', 'juno-1'),
  chainName: env('JUNO_CHAIN_NAME', 'Juno'),
  bip44: {
    coinType: 118
  },
  bech32Config: Bech32Address.defaultBech32Config('juno'),
  stakeCurrency: {
    coinDenom: 'JUNO',
    coinMinimalDenom: 'ujuno',
    coinDecimals: 6,
    coinGeckoId: 'juno-network'
    // coinImageUrl: '/tokens/juno.svg'
  },
  currencies: [
    {
      coinDenom: 'JUNO',
      coinMinimalDenom: 'ujuno',
      coinDecimals: 6,
      coinGeckoId: 'juno-network'
      // coinImageUrl: '/tokens/juno.svg'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'JUNO',
      coinMinimalDenom: 'ujuno',
      coinDecimals: 6,
      coinGeckoId: 'juno-network'
      // coinImageUrl: '/tokens/juno.svg'
    }
  ],
  features: ['stargate', 'ibc-transfer', 'ibc-go', 'no-legacy-stdTx', 'wasmd_0.24+', 'cosmwasm']
}

export const LUNA_CHAIN_INFO: ChainInfo = {
  rpc: env('LUNA_RPC', 'https://terra-fleet.main.stridenet.co/'),
  rest: env('LUNA_REST', 'https://terra-fleet.main.stridenet.co/api'),
  chainId: env('LUNA_CHAIN_ID', 'phoenix-1'),
  chainName: env('LUNA_CHAIN_NAME', 'Terra'),
  bip44: {
    coinType: 330
  },
  bech32Config: Bech32Address.defaultBech32Config('terra'),
  stakeCurrency: {
    coinDenom: 'LUNA',
    coinMinimalDenom: 'uluna',
    coinDecimals: 6,
    coinGeckoId: 'terra'
    // coinImageUrl: '/tokens/atom.svg'
  },
  currencies: [
    {
      coinDenom: 'LUNA',
      coinMinimalDenom: 'uluna',
      coinDecimals: 6,
      coinGeckoId: 'terra'
      // coinImageUrl: '/tokens/atom.svg'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'LUNA',
      coinMinimalDenom: 'uluna',
      coinDecimals: 6,
      coinGeckoId: 'terra'
      // coinImageUrl: '/tokens/atom.svg'
    }
  ],
  features: ['stargate', 'ibc-transfer', 'no-legacy-stdTx', 'ibc-go']
}

export const EVMOS_CHAIN_INFO: ChainInfo = {
  rpc: env('EVMOS_RPC', EDGE_URL.concat('/api/evmos/rpc')),
  rest: env('EVMOS_REST', EDGE_URL.concat('/api/evmos/lcd')),
  chainId: env('EVMOS_CHAIN_ID', 'evmos_9001-2'),
  chainName: env('EVMOS_CHAIN_NAME', 'Evmos'),
  bip44: {
    coinType: 60
  },
  bech32Config: Bech32Address.defaultBech32Config('evmos'),
  stakeCurrency: {
    coinDenom: 'EVMOS',
    coinMinimalDenom: 'aevmos',
    coinDecimals: 18,
    coinGeckoId: 'evmos'
    // coinImageUrl: '/tokens/evmos.svg'
  },
  currencies: [
    {
      coinDenom: 'EVMOS',
      coinMinimalDenom: 'aevmos',
      coinDecimals: 18,
      coinGeckoId: 'evmos'
      // coinImageUrl: '/tokens/evmos.svg'
    }
  ],
  feeCurrencies: [
    {
      coinDenom: 'EVMOS',
      coinMinimalDenom: 'aevmos',
      coinDecimals: 18,
      coinGeckoId: 'evmos'
      // coinImageUrl: '/tokens/evmos.svg'
    }
  ],
  features: ['ibc-transfer', 'ibc-go', 'eth-address-gen', 'eth-key-sign']
}

export const CHAIN_INFO_LIST: Record<SelectedCoinDenom, ChainInfo> = {
  ATOM: GAIA_CHAIN_INFO,
  OSMO: OSMO_CHAIN_INFO,
  STARS: STARS_CHAIN_INFO,
  JUNO: JUNO_CHAIN_INFO,
  LUNA: LUNA_CHAIN_INFO,
  EVMOS: EVMOS_CHAIN_INFO,
  SCRT: SCRT_CHAIN_INFO,
  STRD: STRIDE_CHAIN_INFO
}

export const SUPPORTED_COIN_DENOMS = ['ATOM', 'OSMO', 'STARS', 'JUNO', 'LUNA', 'EVMOS'] as const

export const SUPPORTED_DENOMS_TO_WITHDRAW_ST_TOKEN: Partial<Record<SelectedCoinDenom, boolean>> = {
  LUNA: true,
  EVMOS: true
}

export const SUPPORTED_CHAIN_INFO_LIST = SUPPORTED_COIN_DENOMS.map((denom) => {
  return CHAIN_INFO_LIST[denom]
})
