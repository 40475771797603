// https://github.com/cosmology-tech/cosmodal
//
// This atom including the components consuming it from `page-components/GlobalWalletConnection`
// are based on the npm package `cosmodal`, modified to work within our codebase.
//
// It is meant to support any kinds of wallet although at the moment we only support Keplr and Walletconnect
// different wallets by introducing a Keplr adapter for each of them.
// Cosmodal as well only supports Keplr and WalletConnect through Keplr.
// Cosmodal `getWallet()` api is pretty good so we're sticking with it.
// (The same reason we're also using event emitter here)
//
// This part of the codebase only handles the wallet selection, but does not handle the rest
// of the process (wallet fetching, etc). Checkout `@/atoms/Wallet` for more information.

import React, { createContext, useCallback, useContext, useRef, useState } from 'react'
import { Keplr } from '@keplr-wallet/types'
import { useSetAtom, useAtom } from 'jotai'
import EventEmitter from 'eventemitter3'
import { useLatestValue } from '@/hooks'
import { fatal } from '@/utils'
import {
  defaultConnectionTypeAtom,
  wcQrUriAtom,
  isWalletConnectionModalOpenAtom,
  missingExtensionWalletIdAtom
} from './atoms'
import { WALLET_LIST } from '@/config'
import { WalletInfo, WalletId } from './types'
import { createWalletConnectClient } from './walletconnect/utils'

type ModalEvents = 'selection_modal_close' | 'wc_modal_close' | WalletId

export const WalletConnectionContext = createContext<{
  closeModal: () => void
  closeWcModal: () => void
  getWallet: () => Promise<GetWalletReturnType>
  selectWallet: (id: WalletId) => void
  clearLastUsedWallet: () => void
} | null>(null)

interface GetWalletReturnType {
  walletInfo: WalletInfo
  wallet: Keplr
}

export const WalletConnectionProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [eventListener] = useState(() => new EventEmitter<ModalEvents>())
  const setIsModalOpen = useSetAtom(isWalletConnectionModalOpenAtom)
  const setWcQrUri = useSetAtom(wcQrUriAtom)
  const missingExtensionWalletId = useSetAtom(missingExtensionWalletIdAtom)
  const [defaultConnectionType, setDefaultConnectionType] = useAtom(defaultConnectionTypeAtom)
  const defaultConnectionTypeRef = useLatestValue<WalletId | undefined>(defaultConnectionType)
  // Cache the instance of the last used wallet so we don't have to resolve it again
  const lastUsedWalletInstanceRef = useRef<GetWalletReturnType | null>(null)

  const [getWallet] = useState(() => (): Promise<GetWalletReturnType> => {
    if (typeof window === 'undefined') {
      return Promise.reject(new Error('getWallet should only be run client-side.'))
    }

    // If this function was called previously, we'll just return the current instance
    if (lastUsedWalletInstanceRef.current) {
      return Promise.resolve(lastUsedWalletInstanceRef.current)
    }

    const cleanUp = () => {
      eventListener.off('selection_modal_close')
      eventListener.off('wc_modal_close')
      WALLET_LIST.forEach((walletInfo: WalletInfo) => {
        eventListener.off(walletInfo.id)
      })
    }

    let wcCallbackClosed: Function | undefined

    const handleWcOpen = (uri: string, cb: Function) => {
      setWcQrUri(uri)
      wcCallbackClosed = cb
    }

    const handleWcClose = () => {
      setWcQrUri('')
    }

    // In case you're wondering: Keplr is a simple browser extension so there's less
    // code to make it work, while WalletConnect has a bit more process.
    // Likely the same for other wallets in the future.
    //
    // In the future, let's try to make separate hooks for different clients that
    // require code similar to this.
    const wc = createWalletConnectClient({
      onOpen: handleWcOpen,
      onClose: handleWcClose
    })

    const resolveWallet = async (walletInfo: WalletInfo): Promise<GetWalletReturnType> => {
      const wallet = await walletInfo.getWallet({ wc })

      // If a non-extension wallet returns undefined, that's very likely an error from our side
      // wherein we forgot to add extension details to the wallet information.
      // @TODO: Let's add a notification handle here (or from useConnectWallet).
      if (wallet == null && walletInfo.extension == null) {
        throw fatal(`Wallet ${walletInfo.name} (${walletInfo.id}) is missing but has no extension details.`)
      }

      // If wallet is null, it means that the wallet extension is not installed / could not be found.
      // In this case, we want to link them to the default wallet's download URL.
      // We'll also force log out them so *we* don't unintentionally "connect" when they refresh / revisit the page.
      // This could happen happen if they connect to Stride, and then later on uninstall the extension.
      if (wallet == null) {
        missingExtensionWalletId(walletInfo.id)
        setDefaultConnectionType(undefined)
        const error = new Error(`Browser extension for wallet ${walletInfo.id} could not be found.`)
        return Promise.reject(error)
      }

      const value: GetWalletReturnType = {
        walletInfo,
        wallet
      }

      lastUsedWalletInstanceRef.current = value
      setDefaultConnectionType(walletInfo.id)
      cleanUp()
      return Promise.resolve(value)
    }

    const defaultConnectionWalletInfo: WalletInfo | undefined = WALLET_LIST.find((walletInfo: WalletInfo) => {
      return defaultConnectionTypeRef.current === walletInfo.id
    })

    if (defaultConnectionWalletInfo) {
      return resolveWallet(defaultConnectionWalletInfo)
    }

    return new Promise<GetWalletReturnType>((resolve, reject) => {
      setIsModalOpen(true)

      const handlers: Record<WalletId, (w: WalletInfo) => void> = {
        'keplr-wallet-extension': (walletInfo) => {
          resolveWallet(walletInfo).then(resolve, reject)
        },

        'walletconnect-keplr': (walletInfo) => {
          if (wc.connected) {
            return resolveWallet(walletInfo).then(resolve, reject)
          }

          wc.createSession()

          wc.on('connect', (error: Error | null) => {
            if (error) {
              return reject(error)
            }

            resolveWallet(walletInfo).then(resolve, reject)
          })
        },

        'leap-wallet-extension': (walletInfo) => {
          resolveWallet(walletInfo).then(resolve, reject)
        },

        'cosmostation-wallet-extension': (walletInfo) => {
          resolveWallet(walletInfo).then(resolve, reject)
        }
      }

      eventListener.on('selection_modal_close', () => {
        setIsModalOpen(false)
        reject()
        cleanUp()
      })

      eventListener.on('wc_modal_close', () => {
        setWcQrUri('')
        reject()
        wcCallbackClosed?.()
        cleanUp()
      })

      WALLET_LIST.forEach((walletInfo) => {
        eventListener.on(walletInfo.id, async () => {
          setIsModalOpen(false)
          handlers[walletInfo.id](walletInfo)
        })
      })
    })
  })

  const closeModal = () => {
    eventListener.emit('selection_modal_close')
  }

  const closeWcModal = () => {
    eventListener.emit('wc_modal_close')
  }

  const selectWallet = (walletId: WalletId) => {
    eventListener.emit(walletId)
  }

  const clearLastUsedWallet = useCallback(() => {
    lastUsedWalletInstanceRef.current = null
    setDefaultConnectionType(undefined)
  }, [])

  return (
    <WalletConnectionContext.Provider
      value={{
        closeModal,
        closeWcModal,
        getWallet,
        selectWallet,
        clearLastUsedWallet
      }}>
      {children}
    </WalletConnectionContext.Provider>
  )
}

export const useWalletConnection = () => {
  const context = useContext(WalletConnectionContext)

  if (!context) {
    throw new Error('You forgot to use WalletConnectionProvider')
  }

  return context
}
