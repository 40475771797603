import { useQuery } from 'react-query'
import { useAtomValue } from 'jotai'
import { stride } from 'stridejs'
import { SelectedCoinDenom, strideAccountAtom } from '@/atoms/Wallet'
import { queryKeys } from '@/query-keys'
import { fatal } from '@/utils'
import { AIRDROP_IDENTIFIERS, STRIDE_CHAIN_INFO, AIRDROP_IDENTIFIER_DENOMS } from '@/config'

interface UseEligibleAirdropsQueryParams {
  onError?: (e: Error) => void
}

const useEligibleAirdropsQuery = ({ onError }: UseEligibleAirdropsQueryParams = {}) => {
  const strideAccount = useAtomValue(strideAccountAtom)

  const handleRequest = async (): Promise<SelectedCoinDenom[]> => {
    if (!strideAccount) {
      throw fatal('Unable to request eligible airdrops while wallet is disconnected.')
    }

    const client = await stride.ClientFactory.createRPCQueryClient({
      rpcEndpoint: STRIDE_CHAIN_INFO.rpc
    })

    const eligibleAirdrops: SelectedCoinDenom[] = []

    for (const identifier of AIRDROP_IDENTIFIERS) {
      const data = await client.stride.claim.claimRecord({
        address: strideAccount.key.bech32Address,
        airdropIdentifier: identifier
      })

      if (data.claimRecord.actionCompleted.length) {
        eligibleAirdrops.push(AIRDROP_IDENTIFIER_DENOMS[identifier])
      }
    }

    return eligibleAirdrops
  }

  return useQuery(
    queryKeys.globalEligibleAirdropsByAddress({ address: strideAccount?.key.bech32Address ?? '' }),
    handleRequest,
    { enabled: Boolean(strideAccount), onError, retry: false }
  )
}

export { useEligibleAirdropsQuery }
