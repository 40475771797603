import React, { useContext } from 'react'
import { UseFormReturnType } from '@mantine/form/lib/use-form'
import { once } from 'lodash'

// @source: https://stackoverflow.com/a/61020816/2698227
const createStateContext = once(<T,>() => React.createContext({} as UseFormReturnType<T>))

type FormProviderProps<T> = { children: React.ReactNode } & UseFormReturnType<T>

// Mantine looks like a subset of react-hook-form. This code completes Mantine Form
// by introducing this pattern to share form state on nested components.
// This is also similar to StepperModal, and is used like so:
//
// Parent Component (hook declaration) - const form = useForm()
// Parent Component (render) - <FormProvider {...form}></FormProvider>
// Nested Components - const form = useFormContext()
//
// Check out how it's used on `src/pages/index.tsx` (Stake Form + Unstake Form)
function FormProvider<T>({ children, ...form }: FormProviderProps<T>): React.ReactElement {
  const Context = createStateContext<T>()
  return <Context.Provider value={form}>{children}</Context.Provider>
}

function useFormContext<T>(): UseFormReturnType<T> {
  const Context = createStateContext<T>()
  return useContext(Context)
}

export { FormProvider, useFormContext }
