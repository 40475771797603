const queryKeys = {
  globalApy: ['global-apy'],
  globalRedemptionRecordsBase: ['global-redemption-records'],
  globalRedemptionRecordsByAddress: ({ address }: Record<'address', string>) => ['global-redemption-records', address],
  globalAnnouncementFlags: ['global-announcement-flags'],
  globalEligibleAirdropsBase: ['global-eligible-airdrops'],
  globalEligibleAirdropsByAddress: ({ address }: Record<'address', string>) => ['global-eligible-airdrops', address],
  globalHostZoneBase: ['global-host-zone'],
  globalHostZoneFromChainId: ({ chainId }: Record<'chainId', string>) => ['global-host-zone', chainId],

  airdropTasksBase: ['airdrop-tasks'],
  airdropTasksByAddress: ({ address }: Record<'address', string>) => ['airdrop-tasks', address],
  airdropClaimHistoryBase: ['airdrop-claim-history'],
  airdropClaimHistoryByAddress: ({ address }: Record<'address', string>) => ['airdrop-claim-history', address],
  airdropTotalClaimableBase: ['airdrop-total-claimable'],
  airdropTotalClaimableAddress: ({ address }: Record<'address', string>) => ['airdrop-total-claimable', address],
  airdropRebateProgramBase: ['airdrop-rebate-program'],
  airdropRebateProgramByAddress: ({ address }: Record<'address', string>) => ['airdrop-rebate-program', address],
  airdropClaimRecordBase: ['airdrop-claim-record'],
  airdropClaimRecordByAddress: ({ address }: Record<'address', string>) => ['airdrop-claim-record', address],
  airdropClaimEligibleAirdrops: ['airdrop-eligible-airdrops'],
  airdropClaimEligibleAirdropsByAddress: ({ address }: Record<'address', string>) => [
    'airdrop-eligible-airdrops',
    address
  ],

  stakingModalLiquidityPoolApyBase: ['global-liquidity-pool-apy'],
  stakingModalLiquidityPoolApyByDenom: ({ denom }: Record<'denom', string>) => ['global-liquidity-pool-apy', denom],
  stakingStatsOsmosisMarketPriceBase: ['staking-stats-osmosis-market-price'],
  stakingStatsOsmosisMarketPriceByDenom: ({ denom }: Record<'denom', string>) => [
    'staking-stats-osmosis-market-price',
    denom
  ],

  unstakeFormEpochTrackerBase: ['unstake-form-epoch-tracker'],
  unstakeFormRedemptionRecordBase: ['unstake-form-redemption-record'],
  unstakeFormRedemptionRecord: ({ address, epochNumber }: Record<'address' | 'epochNumber', string>) => {
    return [...queryKeys.unstakeFormRedemptionRecordBase, address, epochNumber]
  },

  stakeFormLiquidityPoolAprBase: ['stake-form-liquidity-pool-apr'],
  stakeFormLiquidityPoolAprByAddress: ({ address }: Record<'address', string>) => [
    'stake-form-liquidity-pool-apr',
    address
  ],

  transactionHistoryBase: ['transaction-history'],
  transactionHistoryByAddresses: ({ addresses }: { addresses: Record<string, string> }) => [
    'transaction-history',
    addresses
  ],
  transactionHistoryIbcStatusBase: ['transaction-history-ibc-status'],
  transactionHistoryIbcStatusByHash: ({ hash }: Record<'hash', string>) => ['transaction-history-ibc-status', hash],

  liquidityPoolStakeTransactionBase: ['liquidity-pool-stake-transaction'],
  liquidityPoolStakeTransactionByHash: ({ hash }: Record<'hash', string>) => ['liquidity-pool-stake-transaction', hash]
}

export { queryKeys }
