import { notify } from '@/atoms/Notifications'
import { convertDenomToMicroDenom } from '@/atoms/Wallet'
import { CHAIN_INFO_LIST } from '@/config'
import { disregard, fatal, poll } from '@/utils'
import axios from 'axios'
import { useAtom } from 'jotai'
import { useMutation, useQueryClient } from 'react-query'
import { queryKeys } from '@/query-keys'
import { assertIsDeliverTxSuccess } from '../utils'
import { selectedAccountAtom, strideAccountAtom } from '../atoms'
import { TX_MSG } from '../registry'
import { useUpdateAccountBalances } from './useUpdateAccountBalances'

export interface UseClaimTokensParameters {
  epoch: string
}

// @TODO: Let's make the sign and broadcast call separate
// so that we're able to display the correct text if
// the user already approved the transaction
const useClaimTokens = () => {
  const [strideAccount] = useAtom(strideAccountAtom)

  const [selectedAccount] = useAtom(selectedAccountAtom)

  const { updateAccountBalances } = useUpdateAccountBalances()

  const client = useQueryClient()

  const verifyRedemptionRecordIsClaimed = async (epoch: string) => {
    if (!strideAccount || !selectedAccount) {
      // User should not be able to exit while unstake wizard is on-going
      throw fatal('Unable to verify removal record redemption while disconnected')
    }

    const selectedChainInfo = CHAIN_INFO_LIST[selectedAccount.currency.coinDenom]

    const strideChainInfo = CHAIN_INFO_LIST[strideAccount.currency.coinDenom]

    const instance = axios.create({ baseURL: strideChainInfo.rest })

    const redemptionRecordId = [selectedChainInfo.chainId, epoch, strideAccount.key.bech32Address].join('.')

    await poll(
      () => {
        return instance.get(`/Stride-Labs/stride/records/user_redemption_record/${redemptionRecordId}`, {
          validateStatus(status) {
            // When the redemption record doesn't exist, the endpoint
            // doesn't implement a normal 404 error code. It acts
            // as if the "method" we're requesting doesn't exist.
            if (status === 501) return true
            // Default status validation
            return status >= 200 && status < 300
          }
        })
      },
      (res) => res.status === 404,
      // We're giving the chain a 5 minute window to properly claim the record. If this fails, it's fine.
      { ms: 3000, max: 100 }
    )
  }

  return useMutation<unknown, unknown, UseClaimTokensParameters>('wallet/claim-tokens', async ({ epoch }) => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('Trying to claim while disconnected')
    }

    const chainInfo = CHAIN_INFO_LIST[selectedAccount.currency.coinDenom]

    const msgClaimUndelegatedTokens = {
      typeUrl: TX_MSG.MsgClaimUndelegatedTokens,
      value: {
        creator: strideAccount.key.bech32Address,
        hostZoneId: chainInfo.chainId,
        epoch,
        sender: strideAccount.key.bech32Address
      }
    }

    const fee = {
      amount: [
        {
          amount: '0',
          denom: strideAccount.currency.coinMinimalDenom
        }
      ],
      gas: String(convertDenomToMicroDenom(1))
    }

    const tx = await strideAccount.client.signAndBroadcast(
      strideAccount.key.bech32Address,
      [msgClaimUndelegatedTokens],
      fee,
      ''
    )

    assertIsDeliverTxSuccess(tx)

    try {
      await verifyRedemptionRecordIsClaimed(epoch)
    } catch (e) {
      notify.error(
        'Oops, something crashed. If you do not receive your claimed tokens after refreshing the page, please contact support.'
      )
      disregard(e)
    }

    await client.invalidateQueries(queryKeys.transactionHistoryBase)

    try {
      await updateAccountBalances(strideAccount)
      await updateAccountBalances(selectedAccount)
    } catch (e) {
      notify.error('Attempt to refresh your balance failed. Please refresh the page.')
      disregard(e)
    }
  })
}

export { useClaimTokens }
