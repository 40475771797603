// const stepper = useStepper({
//   steps,
//   isOpen,
//   isComplete,
//   onClose,
//   onComplete,
//   error,
//   loading
// })

import React, { createContext, useState, useContext, useRef } from 'react'
import { useLatestValue } from '@/hooks'
import { delay } from '@/utils'

// stepper.onForceClose
// stepper.start()
// stepper.nextStep()
// stepper.jumpStep()
// stepper.repeatStep()
// stepper.complete()

// <StepperProvider {...stepper}>
//   <StepperModal>
//     <StakeModalStepOne />
//     <StakeModalStepTwo />
//     <StakeModalStepThree />
//     <StakeModalStepFour />
//   </StepperModal>
// </StepperProvider>

interface UseStepperParams {
  steps: string[]
  error: boolean
  warning: boolean
  isOpen: boolean
  isLoading: boolean
  isComplete: boolean
  onClose: () => void
  onComplete?: () => void
}

interface UseStepperPayload {
  // @TODO: Might make sense to move all params under a single object
  // so it's simple to understand which props are being drilled down
  // and which props are actually initialized/provided by the Stepper.
  activeStep: number
  stepKey: number
  steps: string[]
  error: boolean
  warning: boolean
  isOpen: boolean
  isLoading: boolean
  isOnGoing: boolean
  isComplete: boolean
  start: () => void
  nextStep: () => void
  previousStep: () => void
  repeatStep: () => void
  jumpStep: (step: number) => void
  complete: () => void
  // Use this for the final step when the whole flow completes.
  // This method does not fire if the flow is on-going or has not completed yet
  close: () => void
  // Use this for everything else; if you want the user to exit the modal
  // while it has not completed yet.
  forceClose: () => void
  // This lets stepper components run step-specific callback when modal gets closed during that step.
  // Use-case: StakeModalStepThree can append a pending transaction when the modal closes (aka user "minimizes" the modal).
  // We reset this whenever a user goes to another step so it doesn't fire if it's not overriden by the the following step.
  handleClose: (callback: () => void) => void
}

const StepperProviderContext = createContext<UseStepperPayload>({} as UseStepperPayload)

const useStepper = (params: UseStepperParams): UseStepperPayload => {
  const [activeStep, setActiveStep] = useState<number>(0)

  const [stepKey, setStepKey] = useState<number>(0)

  const [isOnGoing, setIsOnGoing] = useState(false)

  // @TODO: Would be nice to make consolidate both "on-going" and "is-complete"
  // into a single state with values "uninitialized", "pending", and "complete"
  const [isComplete, setIsComplete] = useState(false)

  const closeCallbackRef = useRef<Function | null>(null)

  const isOpenRef = useLatestValue(params.isOpen)

  const lastStep = params.steps.length - 1

  const reset = async () => {
    // We'll delay the state reset for a whole 200 seconds which is
    // roughly the same closing duration for the modal.
    // There's a small window while the closing animation is on-going
    // that would remount the first step, unintentionally triggering effects.
    // This also avoids the modal from flashing any changes.
    await delay(500)
    closeCallbackRef.current = null
    setActiveStep(0)
    setIsOnGoing(false)
    setIsComplete(false)
  }

  const start = () => {
    if (!isOpenRef.current) return
    setIsOnGoing(true)
  }

  const nextStep = () => {
    if (!isOpenRef.current) return
    closeCallbackRef.current = null
    setActiveStep(Math.min(activeStep + 1, lastStep))
  }

  const previousStep = () => {
    if (!isOpenRef.current) return
    closeCallbackRef.current = null
    setActiveStep(Math.max(activeStep - 1, 0))
  }

  const jumpStep = (nextStep: number) => {
    if (!isOpenRef.current) return
    closeCallbackRef.current = null
    setActiveStep(nextStep)
  }

  // This lets a stepper component to remount itself
  // this means having to re-run mount effects and reinitialize state
  // We're using something different currently, so let's revisit this in the future.
  const repeatStep = () => {
    if (!isOpenRef.current) return
    setStepKey((stepKey) => stepKey + 1)
  }

  const complete = () => {
    if (!isOpenRef.current) return
    jumpStep(lastStep)
    setIsOnGoing(false)
    setIsComplete(true)
  }

  const close = () => {
    if (isOnGoing) return
    if (isComplete) params.onComplete?.()
    closeCallbackRef.current?.()
    params.onClose()
    reset()
  }

  const forceClose = () => {
    closeCallbackRef.current?.()
    params.onClose()
    reset()
  }

  const handleClose = (callback: () => void) => {
    closeCallbackRef.current = callback
  }

  return {
    activeStep,
    stepKey,
    steps: params.steps,
    error: params.error,
    warning: params.warning,
    isOpen: params.isOpen,
    isLoading: params.isLoading,
    isOnGoing,
    isComplete,
    start,
    nextStep,
    previousStep,
    jumpStep,
    repeatStep,
    complete,
    close,
    forceClose,
    handleClose
  }
}

const StepperProvider: React.FC<UseStepperPayload & { children: React.ReactNode }> = ({ children, ...params }) => {
  return <StepperProviderContext.Provider value={params}>{children}</StepperProviderContext.Provider>
}

const useStepperContext = () => {
  return useContext(StepperProviderContext)
}

export { useStepper, StepperProvider, useStepperContext }
