import { useEligibleAirdropsQuery } from '@/queries'
import { fatal } from '@/utils'
import BigNumber from 'bignumber.js'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { useAtom } from 'jotai'
import { useMutation } from 'react-query'
import { selectedAccountAtom, strideAccountAtom } from '../atoms'
import { TX_MSG } from '../registry'
import { convertDenomToMicroDenom, cleanMicroDenom } from '../utils'

const useSignStakeToken = () => {
  const [selectedAccount] = useAtom(selectedAccountAtom)

  const [strideAccount] = useAtom(strideAccountAtom)

  const { data: eligibleAirdrops } = useEligibleAirdropsQuery()

  return useMutation('wallet/sign-stake-token', async (amount: number): Promise<TxRaw> => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('You are unable to stake without connecting your wallet.')
    }

    if (!eligibleAirdrops) {
      // Eligible airdrops data should have been loaded by the staking form.
      // If the user gets here while eligible airdrop is missing, there are a few likely things:
      // We forgot to run the eligible airdrop query in the staking form or forgot to show a loader.
      // We did not disable the action that leads to this code (either it is loading or resulted to an error).
      throw fatal('Unable to calculate gas multiplier as eligible airdrops have not been fetched yet.')
    }

    const gasMultiplier = Math.max(eligibleAirdrops.length, 1)

    const gasAmount = new BigNumber(0.5).multipliedBy(gasMultiplier)

    const amountInMicroDenom = convertDenomToMicroDenom(amount, selectedAccount.currency.coinMinimalDenom)

    const fee = {
      amount: [{ amount: '0', denom: strideAccount.currency.coinMinimalDenom }],
      gas: String(convertDenomToMicroDenom(gasAmount))
    }

    const msgLiquidStake = {
      typeUrl: TX_MSG.MsgLiquidStake,
      value: {
        creator: strideAccount.key.bech32Address,
        amount: cleanMicroDenom(amountInMicroDenom),
        hostDenom: selectedAccount.currency.coinMinimalDenom
      }
    }

    // Handle this from StakeModal
    return await strideAccount.client.sign(strideAccount.key.bech32Address, [msgLiquidStake], fee, '')
  })
}

export { useSignStakeToken }
