import { Keplr } from '@keplr-wallet/types'

const getLeapFromWindow = async (): Promise<Keplr | undefined> => {
  if (typeof window === 'undefined') {
    return undefined
  }

  if (window.leap) {
    return window.leap
  }

  if (document.readyState === 'complete') {
    return window.leap
  }

  return new Promise((resolve) => {
    const documentStateChange = () => {
      if (document.readyState === 'complete') {
        resolve(window.leap)
        document.removeEventListener('readystatechange', documentStateChange)
      }
    }

    document.addEventListener('readystatechange', documentStateChange)
  })
}

export { getLeapFromWindow }
