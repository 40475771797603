import { useEffect } from 'react'
import { useLatestValue } from './useLatestValue'

type Listener<T extends keyof WindowEventMap> = (event: WindowEventMap[T]) => void

const useWindowListener = <T extends keyof WindowEventMap>(event: T | null, listener: Listener<T>) => {
  const listenerRef = useLatestValue(listener)

  useEffect(() => {
    if (event == null) return
    const handler: Listener<T> = (event) => listenerRef.current?.(event)
    window.addEventListener(event, handler)
    return () => window.addEventListener(event, handler)
  }, [event, listenerRef])
}

export { useWindowListener }
