import { LogArgument } from 'rollbar'
import { rollbar } from '@/rollbar'

export class StrideFatalError extends Error {}

// Alias for throwing new error so intent is clear that
// code path is a type guard and it's unlikely to happen.
// But on the very, very rare chance it does, we'll throw.
//
// For asynchronous operations (only done with react-query), this will simply notify us through Rollbar.
// For everything else, we'll send a Rollbar notification + our error boundary will take care of it.
export const fatal = (message: string) => {
  rollbar.error(`(Stride Fatal): ${message}`)
  return new StrideFatalError(message)
}

const isValidError = (v: unknown): v is LogArgument => {
  return (
    v instanceof Error ||
    v instanceof Date ||
    Array.isArray(v) ||
    typeof v === 'string' ||
    typeof v === 'function' ||
    typeof v === 'object' ||
    v === undefined
  )
}

// Alias for simply logging the error so intent is clear that
// we don't necessarily want to act on this error.
// We will still send a Rollbar notification for awareness.
//
// You might want to send a toast notification before calling this.
export const disregard = (e: unknown) => {
  const message = isValidError(e) ? e : 'Unknown error type'

  rollbar.error('(Stride Disregard):', message)

  console.error(e)
}
