import Long from 'long'
import { BaseAccount } from 'cosmjs-types/cosmos/auth/v1beta1/auth'
import { Any } from 'cosmjs-types/google/protobuf/any'
import { assert } from '@cosmjs/utils'
import { accountFromBaseAccount, strideAccountParser, Account } from 'stridejs'
import { ethermint } from '@evmos/proto/dist/proto/ethermint/types/v1/account'

// Adds evmos account parser to the Stride parser.
export function accountParser(input: Any): Account {
  const { typeUrl, value } = input

  if (typeUrl === '/ethermint.types.v1.EthAccount') {
    const baseAccount = ethermint.types.v1.EthAccount.deserialize(value)?.base_account
    assert(baseAccount)
    const decoded: BaseAccount = {
      address: baseAccount.address,
      accountNumber: Long.fromValue(baseAccount.account_number ? baseAccount.account_number : 0),
      sequence: Long.fromValue(baseAccount.sequence ? baseAccount.sequence : 0)
    }
    return accountFromBaseAccount(decoded)
  }

  return strideAccountParser(input)
}
