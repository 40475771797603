import BigNumber from 'bignumber.js'

// Development tool to simulate data fetching
export const delay = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export const fromUnixTimestamp = (timestamp: string | number): number => {
  return new BigNumber(timestamp).multipliedBy(1_000).toNumber()
}

export const fromNanosecondTimestamp = (timestamp: string | number): number => {
  return new BigNumber(timestamp).dividedBy(1_000_000).toNumber()
}
