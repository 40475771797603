import { useQuery } from 'react-query'
import { Redis } from '@upstash/redis'
import { queryKeys } from '@/query-keys'
import { config } from '@/config'
import { rollbar } from '@/rollbar'

const redis = new Redis({
  url: config.upstashRedis.announcement.url,
  token: config.upstashRedis.announcement.token
})

interface AnnouncementFlags {
  upgrade: boolean
  maintenance: boolean
}

const useAnnouncementFlags = () => {
  const handleRequest = async (): Promise<AnnouncementFlags> => {
    const [upgrade, maintenance] = await Promise.all([redis.get<boolean>('upgrade'), redis.get<boolean>('maintenance')])

    return {
      upgrade: upgrade || false,
      maintenance: maintenance || false
    }
  }

  const handleError = (e: Error) => {
    rollbar.error(e)
  }

  return useQuery(queryKeys.globalAnnouncementFlags, handleRequest, {
    onError: handleError,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchInterval: config.network.announcementRefetchInterval
  })
}

export { useAnnouncementFlags }
