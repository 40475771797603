import React from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { Button } from '@mantine/core'
import { useMediumScreen } from '@/hooks'

const NavigationMenu: React.FC = () => {
  const { pathname } = useRouter()

  const { isMediumScreen } = useMediumScreen()

  if (!pathname.includes('airdrop')) {
    return (
      <Link href="airdrop" passHref>
        <Button fullWidth={!isMediumScreen} component="a">
          Airdrop
        </Button>
      </Link>
    )
  }

  return (
    <Link href="/" passHref>
      <Button variant="outline" fullWidth={!isMediumScreen} component="a">
        Liquid Staking
      </Button>
    </Link>
  )
}

export { NavigationMenu }
