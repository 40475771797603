// @source https://github.com/cosmology-tech/cosmodal/blob/main/src/providers/connectors/walletconnect-keplr.ts
// This is an adapter for WalletConnect to its interfaces similar with Keplr, copied from cosmology-tech/cosmodal as is.

import type {
  AminoSignResponse,
  BroadcastMode,
  OfflineSigner,
  StdSignature,
  StdSignDoc,
  StdTx
} from '@cosmjs/launchpad'
import { DirectSignResponse, OfflineDirectSigner } from '@cosmjs/proto-signing'
import { CosmJSOfflineSigner, CosmJSOfflineSignerOnlyAmino } from '@keplr-wallet/provider'
import {
  ChainInfo,
  Keplr,
  KeplrIntereactionOptions,
  KeplrMode,
  KeplrSignOptions,
  Key,
  EthSignType,
  ICNSAdr36Signatures,
  ChainInfoWithoutEndpoints
} from '@keplr-wallet/types'
import { Buffer } from 'buffer'
import { SecretUtils } from 'secretjs/types/enigmautils'
import { Cosmos } from '@cosmostation/extension-client'
import { SEND_TRANSACTION_MODE } from '@cosmostation/extension-client/cosmos'
import { SendTransactionMode, SignAminoDoc, Amount, Msg } from '@cosmostation/extension-client/types/message'
import Long from 'long'
import { fatal } from '@/utils'

export type KeplrGetKeyWalletCoonectV1Response = {
  address: string
  algo: string
  bech32Address: string
  isNanoLedger: boolean
  name: string
  pubKey: string
}

export type KeplrKeystoreMayChangedEventParam = {
  algo: string
  name: string
  isNanoLedger: boolean
  keys: {
    chainIdentifier: string
    address: string
    bech32Address: string
    pubKey: string
  }[]
}

export class CosmostationKeplr implements Keplr {
  constructor(public readonly provider: Cosmos) {
    this.setupKeyChangeListener()
  }

  readonly version: string = '0.2.5'

  readonly mode: KeplrMode = 'extension'

  defaultOptions: KeplrIntereactionOptions = {}

  protected setupKeyChangeListener() {
    if (typeof window === 'undefined') {
      return
    }

    this.provider.on('accountChanged', () => {
      const event = new Event('cosmostation_keystorechange')
      window.dispatchEvent(event)
    })
  }

  // @DONE
  async enable(chainIds: string | string[]): Promise<void> {
    if (typeof chainIds === 'string') {
      chainIds = [chainIds]
    }

    for (const chainId of chainIds) {
      await this.provider.requestAccount(chainId)
    }
  }

  async disable(_chainIds?: string | string[] | undefined): Promise<void> {
    // try disconnecting from all chains in provider
    await this.provider.disconnect?.()
  }

  async changeKeyRingName(_opts: { defaultName: string; editable?: boolean | undefined }): Promise<string> {
    throw fatal('Not yet implemented')
  }

  // @SKIP
  enigmaDecrypt(_chainId: string, _ciphertext: Uint8Array, _nonce: Uint8Array): Promise<Uint8Array> {
    throw fatal('Not yet implemented')
  }

  // @SKIP
  enigmaEncrypt(
    _chainId: string,
    _contractCodeHash: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    _msg: object
  ): Promise<Uint8Array> {
    throw fatal('Not yet implemented')
  }

  // @DONE
  async experimentalSuggestChain(chainInfo: ChainInfo): Promise<void> {
    const supportedChains = await this.provider.getSupportedChains()

    if ([...supportedChains.official, ...supportedChains.unofficial].includes(chainInfo.chainId)) {
      return
    }

    await this.provider.addChain({
      chainId: chainInfo.chainId,
      chainName: chainInfo.chainName,
      addressPrefix: 'cre',
      baseDenom: chainInfo.stakeCurrency.coinMinimalDenom,
      displayDenom: chainInfo.stakeCurrency.coinDenom,
      restURL: chainInfo.rest,
      coinType: String(chainInfo.bip44.coinType),
      decimals: chainInfo.stakeCurrency.coinDecimals
    })
  }

  // @SKIP
  getEnigmaPubKey(_chainId: string): Promise<Uint8Array> {
    throw fatal('Not yet implemented')
  }

  // @SKIP
  getEnigmaTxEncryptionKey(_chainId: string, _nonce: Uint8Array): Promise<Uint8Array> {
    throw fatal('Not yet implemented')
  }

  // @SKIP
  getEnigmaUtils(_chainId: string): SecretUtils {
    throw fatal('Not yet implemented')
  }

  // @DONE
  async getKey(chainId: string): Promise<Key> {
    const account = await this.provider.getAccount(chainId)

    return {
      name: account.name,
      algo: '', // @FIX
      pubKey: account.publicKey,
      address: Buffer.from(account.address),
      bech32Address: account.address,
      isNanoLedger: account.isLedger,
      isKeystone: false
    }
  }

  // @SKIP
  signArbitrary(_chainId: string, _signer: string, _data: string | Uint8Array): Promise<StdSignature> {
    throw fatal('Not yet implemented')
  }

  // @SKIP
  signEthereum(_chainId: string, _signer: string, _data: string | Uint8Array, _type: EthSignType): Promise<Uint8Array> {
    throw fatal('Not yet implemented')
  }

  // @SKIP
  verifyArbitrary(
    _chainId: string,
    _signer: string,
    _data: string | Uint8Array,
    _signature: StdSignature
  ): Promise<boolean> {
    throw fatal('Not yet implemented')
  }

  // @SKIP
  getOfflineSigner(chainId: string): OfflineSigner & OfflineDirectSigner {
    return new CosmJSOfflineSigner(chainId, this)
  }

  // @DONE
  async getOfflineSignerAuto(chainId: string): Promise<OfflineSigner | OfflineDirectSigner> {
    const key = await this.getKey(chainId)
    if (key.isNanoLedger) {
      return new CosmJSOfflineSignerOnlyAmino(chainId, this)
    }
    return new CosmJSOfflineSigner(chainId, this)
  }

  // @DONE
  getOfflineSignerOnlyAmino(chainId: string): OfflineSigner {
    return new CosmJSOfflineSignerOnlyAmino(chainId, this)
  }

  // @SKIP
  getSecret20ViewingKey(_chainId: string, _contractAddress: string): Promise<string> {
    throw fatal('Not yet implemented')
  }

  // @DONE
  async sendTx(chainId: string, tx: StdTx | Uint8Array, mode: BroadcastMode): Promise<Uint8Array> {
    const txModes: Record<BroadcastMode, SendTransactionMode> = {
      block: SEND_TRANSACTION_MODE.BLOCK,
      sync: SEND_TRANSACTION_MODE.SYNC,
      async: SEND_TRANSACTION_MODE.ASYNC
    }

    const { tx_response } = await this.provider.sendTransaction(
      chainId,
      // @FIX StdTx - Check how we're supposed to send objects to Cosmostation
      // Does it want stringified JSON?
      tx instanceof Uint8Array ? tx : Buffer.from(JSON.stringify(tx)).toString('base64'),
      txModes[mode]
    )

    return Buffer.from(tx_response.txhash, 'hex')
  }

  // @DONE
  async signAmino(
    chainId: string,
    _signer: string,
    signDoc: StdSignDoc,
    signOptions: KeplrSignOptions = {}
  ): Promise<AminoSignResponse> {
    // Fix type compatibility due to Keplr expecting a readonly Coin
    // while Cosmostation requires a normal Coin type.
    const amount: Amount[] = signDoc.fee.amount.map((amount) => {
      return { denom: amount.denom, amount: amount.amount }
    })

    const fee = { ...signDoc.fee, amount }

    // Fix type compatibility due to Keplr expecting a readonly Msg
    // while Cosmostation requires a normal Msg type.
    const msgs: Msg[] = signDoc.msgs.map((msg) => {
      return { type: msg.type, value: msg.value }
    })

    const signDocAmino: SignAminoDoc = { ...signDoc, fee, msgs }

    const { pub_key, signed_doc, signature } = await this.provider.signAmino(chainId, signDocAmino, {
      memo: signOptions.preferNoSetFee,
      fee: signOptions.preferNoSetFee
    })

    return {
      signed: signed_doc,
      signature: {
        pub_key: pub_key,
        signature
      }
    }
  }

  // @DONE
  async signDirect(
    chainId: string,
    _signer: string,
    signDoc: {
      bodyBytes?: Uint8Array | null
      authInfoBytes?: Uint8Array | null
      chainId?: string | null
      accountNumber?: Long | null
    },
    signOptions: KeplrSignOptions = {}
  ): Promise<DirectSignResponse> {
    if (!(signDoc.chainId && signDoc.accountNumber && signDoc.authInfoBytes && signDoc.bodyBytes)) {
      throw fatal('SignDoc is incomplete (chainId, accountNumber, authInfoBytes, or bodyBytes may be null.')
    }

    const { pub_key, signed_doc, signature } = await this.provider.signDirect(
      chainId,
      {
        chain_id: String(signDoc.chainId),
        account_number: signDoc.accountNumber?.toString(),
        auth_info_bytes: signDoc.authInfoBytes,
        body_bytes: signDoc.bodyBytes
      },
      {
        memo: signOptions.preferNoSetMemo,
        fee: signOptions.preferNoSetFee
      }
    )

    return {
      signed: {
        bodyBytes: signed_doc.body_bytes,
        authInfoBytes: signed_doc.auth_info_bytes,
        chainId: signed_doc.chain_id,
        accountNumber: Long.fromString(signed_doc.account_number)
      },

      signature: {
        pub_key,
        signature
      }
    }
  }

  // @SKIP
  suggestToken(_chainId: string, _contractAddress: string, _viewingKey?: string): Promise<void> {
    throw fatal('Not yet implemented')
  }

  // @SKIP
  experimentalSignEIP712CosmosTx_v0(
    _chainId: string,
    _signer: string,
    _eip712: {
      types: Record<
        string,
        | {
            name: string
            type: string
          }[]
        | undefined
      >
      domain: Record<string, any>
      primaryType: string
    },
    _signDoc: StdSignDoc,
    _signOptions?: KeplrSignOptions
  ): Promise<AminoSignResponse> {
    throw fatal('Not yet implemented')
  }

  signICNSAdr36(
    _chainId: string,
    _contractAddress: string,
    _owner: string,
    _username: string,
    _addressChainIds: string[]
  ): Promise<ICNSAdr36Signatures> {
    throw fatal('Not yet implemented')
  }

  getChainInfosWithoutEndpoints(): Promise<ChainInfoWithoutEndpoints[]> {
    throw fatal('Not yet implemented')
  }
}
