import { RefObject, useRef } from 'react'

// Encapsulates ref assignment so events can access fresh values in use effect
function useLatestValue<T>(value: T): RefObject<T> {
  const ref = useRef<T>(value)
  ref.current = value
  return ref
}

export { useLatestValue }
