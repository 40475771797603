import BigNumber from 'bignumber.js'
import { CHAIN_CONFIG, EVMOS_CHAIN_INFO, STRIDE_CHAIN_INFO } from '@/config'

// Multiplier (also known as coinDecimals) should set to the following:
//
// Whether you're sending a transaction, displaying a token value, you should always
// the the decimal count (coinDecimals) of the host / base token. For example, if you
// are sending aevmos or staevmos, you should use the coinDecimals of aevmos (18 in this case).
// To get the coinDecimals, you can either do account.currency.coinDecimals
// or SOME_CHAIN_INFO.stakeCurrency.coinDecimals
//
// If you're using this function, don't forget to test this on Evmos and a non-Evmos chain.
// Right now, there is an idea to require the minimal denom so that everything is
// thought through. However, it may not make any sense given that most of our supported
// chains are Cosmos-based (6-coin decimal standard)) Let's revisit this once we
// start supporting more chains.
//
// In laymans, convertDenomToMicroDenom converts [1 denom] to [1_000_000 micro denom] and vice-versa.
// In case you're not familiar with the scientific notation, 1e6 just means 1_000_000 (6 zeroes).
// 1e18, as you guessed it, just means 1 plus 18 zeroes after it.

// Currently, this makes a simple check on aevmos.
// It does not take into account other chains or tokens that may have different coin decimals (like Evmos)
// @TODO: Once we start having more chains with custom decimals, let's either
// iterate on all the chains or make a look-up map with type Record<SelectedIBCDenom, SelectedCoinDenom>
// Reminder that this function is likely called without being memoized in the render function
// so using an array may not be efficient.
//
// Would be also great to have this typed rather than accepting a string.
// i.e., SelectedCoinMinimalDenom | SelectedCoinIbcDenom
const getDenomCoinDecimals = (minimalDenom: string): number => {
  return minimalDenom === EVMOS_CHAIN_INFO.stakeCurrency.coinMinimalDenom ||
    minimalDenom === CHAIN_CONFIG.EVMOS.ibcDenom ||
    minimalDenom === `st${EVMOS_CHAIN_INFO.stakeCurrency.coinMinimalDenom}` ||
    minimalDenom === CHAIN_CONFIG.EVMOS.stakedIbcDenom
    ? EVMOS_CHAIN_INFO.stakeCurrency.coinDecimals
    : STRIDE_CHAIN_INFO.stakeCurrency.coinDecimals
}

export const convertMicroDenomToDenom = (amount: number | string | BigNumber, minimalDenom: string = ''): number => {
  const multiplier = getDenomCoinDecimals(minimalDenom)
  const value = Number(amount)
  const multiplierValue = new BigNumber(`1e+${multiplier}`)
  return isNaN(value) ? 0 : new BigNumber(value).dividedBy(multiplierValue).toNumber()
}

export const convertDenomToMicroDenom = (amount: number | string | BigNumber, minimalDenom: string = ''): number => {
  const multiplier = getDenomCoinDecimals(minimalDenom)
  const value = Number(amount)
  const multiplierValue = new BigNumber(`1e+${multiplier}`)
  return isNaN(value) ? 0 : new BigNumber(value).multipliedBy(multiplierValue).toNumber()
}

// 15 is the max range of JavaScript numbers, but we'll set to 14 for safety.
const PRECISION_IMPOSED_LIMIT = 14

// This function removes all the decimals from the amount. It also handles the
// the 15-digit max range set to JavaScript numbers which also affects BigNumber.js.
// This is done by getting the first 14 digits of the amount then setting the remaining
// decimals to 0. Also returns a string (that returns its original integer form) so it's
// ready to be set as the transaction amount.
//
// @SEE https://mikemcl.github.io/bignumber.js/#debug
// @TODO: https://github.com/Stride-Labs/interface/issues/227
export const cleanMicroDenom = (amount: number | string | BigNumber, minimalDenom: string = ''): string => {
  const decimals = getDenomCoinDecimals(minimalDenom)

  const integerAsString = new BigNumber(amount).integerValue(BigNumber.ROUND_DOWN).toFormat({ groupSeparator: '' })

  // I have no idea how to get how many digits we have without converting it to a string.
  // If there's a way to do this with simple mathematical operations, let's refactor this.
  if (integerAsString.length <= PRECISION_IMPOSED_LIMIT) {
    return integerAsString
  }

  const excess = decimals - PRECISION_IMPOSED_LIMIT

  // 793146046860035196 -> 79314604686003.52 -> 79314604686003 -> 793146046860030000
  // We'll use `toFormat` to avoid being converted to scientific notation.
  return new BigNumber(amount)
    .shiftedBy(-excess)
    .integerValue(BigNumber.ROUND_DOWN)
    .shiftedBy(excess)
    .toFormat({ groupSeparator: '' })
}
