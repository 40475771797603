import React from 'react'
import { Box, Stack } from '@mantine/core'
import { GlobalAnnouncement } from '../GlobalAnnouncement'
import { GlobalWalletConnection } from '../GlobalWalletConnection'
import { Navigation } from './Navigation'
import { Footer } from './Footer'

interface GlobalLayoutProps {
  children: React.ReactNode
}

const GlobalLayout: React.FC<GlobalLayoutProps> = ({ children }) => {
  return (
    <>
      <GlobalAnnouncement />

      <Stack spacing={0} justify="space-between" sx={(t) => ({ minHeight: t.other.root.minHeight })}>
        <Box>
          <Navigation />
          {children}
        </Box>

        <Footer />
      </Stack>

      <GlobalWalletConnection />
    </>
  )
}

export { GlobalLayout }
