import { useMutation } from 'react-query'
import { useAtom } from 'jotai'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { fatal } from '@/utils'
import { CHAIN_CONFIG } from '@/config'
import { generateIbcTimeoutTimestamp } from '@/atoms/Wallet/ibc/utils'
import { selectedAccountAtom, strideAccountAtom } from '../atoms'
import { signIbcTransaction, TxRawEip712 } from '../ibc'
import { cleanMicroDenom, convertDenomToMicroDenom } from '../utils'

export interface SignSendTokenMutationParameters {
  amount: number
  mode: 'deposit' | 'withdraw'
}

const useSignSendToken = () => {
  const [selectedAccount] = useAtom(selectedAccountAtom)

  const [strideAccount] = useAtom(strideAccountAtom)

  return useMutation(
    'wallet/sign-send-token',
    async ({ amount, mode }: SignSendTokenMutationParameters): Promise<TxRaw | TxRawEip712> => {
      if (!strideAccount || !selectedAccount) {
        throw fatal('You are unable to send token without connecting your wallet.')
      }

      const sender = mode === 'deposit' ? selectedAccount : strideAccount

      const receiver = mode === 'deposit' ? strideAccount : selectedAccount

      const tokenMinimalDenom =
        mode === 'deposit'
          ? selectedAccount.currency.coinMinimalDenom
          : CHAIN_CONFIG[selectedAccount.currency.coinDenom].ibcDenom

      const amountInMicroDenom = convertDenomToMicroDenom(amount, tokenMinimalDenom)

      const fee = {
        amount: [{ amount: '0', denom: sender.currency.coinMinimalDenom }],
        gas: String(convertDenomToMicroDenom(0.25))
      }

      const sourceChannel =
        mode === 'deposit'
          ? CHAIN_CONFIG[selectedAccount.currency.coinDenom].depositChannel
          : CHAIN_CONFIG[selectedAccount.currency.coinDenom].withdrawChannel

      const msgTransfer = {
        typeUrl: '/ibc.applications.transfer.v1.MsgTransfer',
        value: {
          sourcePort: 'transfer',
          sourceChannel,
          token: {
            amount: cleanMicroDenom(amountInMicroDenom),
            denom: tokenMinimalDenom
          },
          sender: sender.key.bech32Address,
          receiver: receiver.key.bech32Address,
          timeoutTimestamp: generateIbcTimeoutTimestamp()
        }
      }

      return await signIbcTransaction(sender, sender.key.bech32Address, [msgTransfer], fee, '')
    }
  )
}

export { useSignSendToken }
