import React from 'react'
import { Icon } from '@/components'
import { Box, UnstyledButton } from '@mantine/core'

interface CustomModalCloseButton {
  onClick: () => void
}

const CustomModalCloseButton: React.FC<CustomModalCloseButton> = ({ onClick }) => {
  return (
    <Box
      sx={(t) => ({ position: 'absolute', top: t.other.globalWalletConnectionCustomModalCloseButton.top, right: 0 })}>
      <UnstyledButton onClick={onClick}>
        <Icon name="close" sx={(t) => ({ color: t.colors.white })} />
      </UnstyledButton>
    </Box>
  )
}

export { CustomModalCloseButton }
