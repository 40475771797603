import { WalletId } from './types'

const walletKeyChangeEvent: Record<WalletId, keyof WindowEventMap | null> = {
  'keplr-wallet-extension': 'keplr_keystorechange',
  'walletconnect-keplr': null,
  'leap-wallet-extension': 'leap_keystorechange',
  'cosmostation-wallet-extension': 'cosmostation_keystorechange'
}

export { walletKeyChangeEvent }
