import React, { useState } from 'react'
import { QueryClient, QueryClientProvider as OriginalQueryClientProvider } from 'react-query'

const QueryClientProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [client] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnReconnect: false,
            refetchOnWindowFocus: false
          }
        }
      })
  )
  return <OriginalQueryClientProvider client={client}>{children}</OriginalQueryClientProvider>
}

export { QueryClientProvider }
