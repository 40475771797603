import React, { useMemo } from 'react'
import { Text, Sx, useMantineTheme, MantineNumberSize, MantineTheme } from '@mantine/core'
import * as icons from './icons'

export type IconName = keyof typeof icons

export interface IconProps {
  name: IconName
  fill?: string
  sx?: Sx
  size?: MantineNumberSize
  className?: string
}

const Icon: React.FC<IconProps> = ({ name, fill = 'currentColor', sx, size = 'md', className }) => {
  const theme = useMantineTheme()
  const iconSize = typeof size === 'number' ? size : theme.fontSizes[size]
  const SelectedIcon = icons[name]

  const iconSx = useMemo(() => {
    const base = { fontSize: iconSize, lineHeight: 0 }
    if (typeof sx === 'function') return (t: MantineTheme) => ({ ...base, ...sx(t) })
    return { ...base, ...sx }
  }, [sx, iconSize])

  if (!SelectedIcon) return null

  return (
    <Text color={fill} sx={iconSx} className={className}>
      <SelectedIcon height={iconSize} width={iconSize} />
    </Text>
  )
}

export { Icon }
