import { addDays, format } from 'date-fns'
import { config, JUNO_CHAIN_INFO } from '@/config'
import { Account } from '../types'

const formatEstimateRangeFromEpochNumber = (account: Account, epochNumber: number | string) => {
  const initialRange = { start: 21, end: 24 }
  const additionalDays = account.currency.coinDenom === JUNO_CHAIN_INFO.stakeCurrency.coinDenom ? 7 : 4
  const launchDate = new Date(config.network.launchDate)
  const baseDate = addDays(launchDate, Number(epochNumber))
  const startDate = format(addDays(baseDate, initialRange.start + additionalDays), 'MMM d, yyyy')
  const endDate = format(addDays(baseDate, initialRange.end + additionalDays), 'MMM d, yyyy')
  return `${startDate} - ${endDate}`
}

export { formatEstimateRangeFromEpochNumber }
