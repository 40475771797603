import { SelectedCoinDenom, SelectedCoinMinimalDenom } from '@/atoms/Wallet/types'
import { env, fatal } from '@/utils'

// This file contains meta configuration for each chain supported

interface ChainConfig {
  // The denom of the chain's tokens on Stride
  ibcDenom: string
  // The denom of the st token on the chain
  // This is used for the 'Send to Chain' feature i.e., components named WithdrawStToken
  // If the feature is not part of the SUPPORTED_DENOMS_TO_WITHDRAW_ST_TOKEN variable,
  // then you can leave this blank.
  stakedIbcDenom: string
  // The denom of the chain's tokens on Osmosis
  osmosisIbcDenom: string
  // The denom of the chain's st tokens on Osmosis
  osmosisStakedIbcDenom: string
  // IBC channel from chain to Stride
  depositChannel: string
  // IBC channel from Stride to chain
  withdrawChannel: string
  // 0.1 for now, revisit in the future
  minimumGas: number
  // Osmosis pool id
  poolId: string
  // Osmosis pool url
  poolUrl: string
  // Url to the chain's assets
  // This is used for the 'Send to Chain' feature i.e., components named WithdrawStToken
  assetsUrl: string
}

const warnIncorrectChainConfigAccess = (keyword: string) => {
  return fatal(
    `You are accessing Stride's ${keyword} property. This is highly likely a mistake. Use the ${keyword} property of the other chain.`
  )
}

const CHAIN_CONFIG: Record<SelectedCoinDenom, ChainConfig> = {
  ATOM: {
    ibcDenom: 'ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2',
    stakedIbcDenom: '',
    osmosisIbcDenom: 'ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2',
    osmosisStakedIbcDenom: 'ibc/C140AFD542AE77BD7DCC83F13FDD8C5E5BB8C4929785E6EC2F4C636F98F17901',
    depositChannel: env('GAIA_DEPOSIT_CHANNEL', 'channel-391'),
    withdrawChannel: env('GAIA_WITHDRAW_CHANNEL', 'channel-0'),
    minimumGas: 0.1,
    poolId: '803',
    poolUrl: 'https://app.osmosis.zone/pool/803',
    assetsUrl: ''
  },

  STRD: {
    get ibcDenom(): string {
      throw warnIncorrectChainConfigAccess('ibc denom')
    },
    get stakedIbcDenom(): string {
      throw warnIncorrectChainConfigAccess('staked ibc denom')
    },
    get osmosisIbcDenom(): string {
      throw warnIncorrectChainConfigAccess('osmosis ibc denom')
    },
    get osmosisStakedIbcDenom(): string {
      throw warnIncorrectChainConfigAccess('osmosis staked ibc denom')
    },
    get depositChannel(): string {
      throw warnIncorrectChainConfigAccess('deposit channel')
    },
    get withdrawChannel(): string {
      throw warnIncorrectChainConfigAccess('withdraw channel')
    },
    get minimumGas(): number {
      throw warnIncorrectChainConfigAccess('minimum gas')
    },
    get poolId(): string {
      throw warnIncorrectChainConfigAccess('pool id')
    },
    get poolUrl(): string {
      throw warnIncorrectChainConfigAccess('pool url')
    },
    get assetsUrl(): string {
      throw warnIncorrectChainConfigAccess('assets url')
    }
  },

  OSMO: {
    ibcDenom: 'ibc/D24B4564BCD51D3D02D9987D92571EAC5915676A9BD6D9B0C1D0254CB8A5EA34',
    stakedIbcDenom: '',
    osmosisIbcDenom: 'uosmo',
    osmosisStakedIbcDenom: 'ibc/D176154B0C63D1F9C6DCFB4F70349EBF2E2B5A87A05902F57A6AE92B863E9AEC',
    depositChannel: env('OSMO_DEPOSIT_CHANNEL', 'channel-326'),
    withdrawChannel: env('OSMO_WITHDRAW_CHANNEL', 'channel-5'),
    minimumGas: 0.1,
    poolId: '833',
    poolUrl: 'https://app.osmosis.zone/pool/833',
    assetsUrl: ''
  },

  STARS: {
    ibcDenom: 'ibc/7EAE5BEF3A26B64AFBD89828AFDDB1DC7024A0276D22745201632C40E6E634D0',
    stakedIbcDenom: '',
    osmosisIbcDenom: 'ibc/987C17B11ABC2B20019178ACE62929FE9840202CE79498E29FE8E5CB02B7C0A4',
    osmosisStakedIbcDenom: 'ibc/5DD1F95ED336014D00CE2520977EC71566D282F9749170ADC83A392E0EA7426A',
    depositChannel: env('STARS_DEPOSIT_CHANNEL', 'channel-106'),
    withdrawChannel: env('STARS_WITHDRAW_CHANNEL', 'channel-19'),
    minimumGas: 0.1,
    poolId: '810',
    poolUrl: 'https://app.osmosis.zone/pool/810',
    assetsUrl: ''
  },

  SCRT: {
    ibcDenom: 'ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2',
    stakedIbcDenom: '',
    osmosisIbcDenom: '',
    osmosisStakedIbcDenom: '',
    depositChannel: env('SCRT_DEPOSIT_CHANNEL', 'channel-0'),
    withdrawChannel: env('SCRT_WITHDRAW_CHANNEL', 'channel-0'),
    minimumGas: 0.1,
    poolId: '',
    poolUrl: '',
    assetsUrl: ''
  },

  JUNO: {
    ibcDenom: 'ibc/DA356E369C3E5CF6A9F1DCD99CE8ED55FBD595E676A5CF033CE784C060492D5A',
    stakedIbcDenom: '',
    osmosisIbcDenom: 'ibc/46B44899322F3CD854D2D46DEEF881958467CDD4B3B10086DA49296BBED94BED',
    osmosisStakedIbcDenom: 'ibc/84502A75BCA4A5F68D464C00B3F610CE2585847D59B52E5FFB7C3C9D2DDCD3FE',
    depositChannel: env('JUNO_DEPOSIT_CHANNEL', 'channel-139'),
    withdrawChannel: env('JUNO_WITHDRAW_CHANNEL', 'channel-24'),
    minimumGas: 0.1,
    poolId: '817',
    poolUrl: 'https://app.osmosis.zone/pool/817',
    assetsUrl: ''
  },

  LUNA: {
    ibcDenom: 'ibc/E61BCB1126F42A2ED73B4CEA2221C9635BC2102F0417543C38071779F991942E',
    stakedIbcDenom: 'ibc/08095CEDEA29977C9DD0CE9A48329FDA622C183359D5F90CF04CC4FF80CBE431',
    osmosisIbcDenom: '',
    osmosisStakedIbcDenom: '',
    depositChannel: env('LUNA_DEPOSIT_CHANNEL', 'channel-46'),
    withdrawChannel: env('LUNA_WITHDRAW_CHANNEL', 'channel-52'),
    minimumGas: 0.1,
    poolId: '',
    poolUrl: '',
    assetsUrl: 'https://station.terra.money/history'
  },

  EVMOS: {
    ibcDenom: 'ibc/4B322204B4F59D770680FE4D7A565DDC3F37BFF035474B717476C66A4F83DD72',
    stakedIbcDenom: 'ibc/C49FDA9CB980D813492E2DFFF255B01C46E629C73B2B468131F6FE6E14F55754',
    osmosisIbcDenom: '',
    osmosisStakedIbcDenom: '',
    depositChannel: env('EVMOS_DEPOSIT_CHANNEL', 'channel-25'),
    withdrawChannel: env('EVMOS_WITHDRAW_CHANNEL', 'channel-9'),
    minimumGas: 0.1,
    poolId: '',
    poolUrl: '',
    assetsUrl: 'https://app.evmos.org/assets'
  }
}

const READABLE_DENOMS: Record<SelectedCoinMinimalDenom, SelectedCoinDenom> = {
  uatom: 'ATOM',
  uosmo: 'OSMO',
  ustars: 'STARS',
  uscrt: 'SCRT',
  ustrd: 'STRD',
  ujuno: 'JUNO',
  uluna: 'LUNA',
  aevmos: 'EVMOS'
}

export { CHAIN_CONFIG, READABLE_DENOMS }
