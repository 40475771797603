import React, { useContext } from 'react'
import { useAtom } from 'jotai'
import { accountsAtom } from './atoms'
import { useConnectWallet } from './mutations/useConnectWallet'
import { useSignSendToken } from './mutations/useSignSendToken'
import { useBroadcastSendToken } from './mutations/useBroadcastSendToken'
import { useSignStakeToken } from './mutations/useSignStakeToken'
import { useBroadcastStakeToken } from './mutations/useBroadcastStakeToken'
import { useSignUnstakeTokens } from './mutations/useSignUnstakeTokens'
import { useBroadcastUnstakeTokens } from './mutations/useBroadcastUnstakeTokens'
import { useClaimTokens } from './mutations/useClaimTokens'
import { WalletContext } from './context'

const useWalletConnect = () => {
  return useContext(WalletContext)
}

const WalletProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [accounts] = useAtom(accountsAtom)

  if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
    // Helps us debug coin transfers... for now.
    // @ts-ignore
    ;(window.stride = window.stride || {}).accounts = accounts
  }

  const { connectWallet, disconnectWallet, isConnectingWallet, isFetchingSelectedAccount, isFetchingOsmosisAccount } =
    useConnectWallet()

  const {
    mutateAsync: signSendToken,
    isLoading: isSigningSendToken,
    error: signSendTokenError,
    reset: resetSignSendToken
  } = useSignSendToken()

  const {
    mutateAsync: broadcastSendToken,
    isLoading: isBroadcastingSendToken,
    error: broadcastSendTokenError,
    reset: resetBroadcastSendToken
  } = useBroadcastSendToken()

  const {
    mutateAsync: signStakeToken,
    isLoading: isSigningStakeToken,
    error: signStakeTokenError,
    reset: resetSignStakeToken
  } = useSignStakeToken()

  const {
    mutateAsync: broadcastStakeToken,
    isLoading: isBroadcastingStakeToken,
    error: broadcastStakeTokenError,
    reset: resetBroadcastStakeToken,
    isSuccess: isBroadcastStakeTokenSuccess
  } = useBroadcastStakeToken()

  const {
    mutateAsync: signUnstakeTokens,
    isLoading: isSigningUnstakeTokens,
    error: signUnstakeTokensError,
    reset: resetSignUnstakeTokens
  } = useSignUnstakeTokens()

  const {
    mutateAsync: broadcastUnstakeTokens,
    isLoading: isBroadcastingUnstakeTokens,
    error: broadcastUnstakeTokensError,
    reset: resetBroadcastUnstakeTokens
  } = useBroadcastUnstakeTokens()

  const { mutateAsync: claimToken, isLoading: isClaimingToken, error: claimTokenError } = useClaimTokens()

  return (
    <WalletContext.Provider
      value={{
        connectWallet,
        disconnectWallet,
        signSendToken,
        resetSignSendToken,
        broadcastSendToken,
        resetBroadcastSendToken,
        signStakeToken,
        resetSignStakeToken,
        broadcastStakeToken,
        resetBroadcastStakeToken,
        // @ts-ignore
        signUnstakeTokens,
        resetSignUnstakeTokens,
        broadcastUnstakeTokens,
        resetBroadcastUnstakeTokens,
        claimToken,
        isConnectingWallet,
        isFetchingSelectedAccount,
        isFetchingOsmosisAccount,
        isSigningSendToken,
        isBroadcastingSendToken,
        isSigningStakeToken,
        isBroadcastingStakeToken,
        isBroadcastStakeTokenSuccess,
        isSigningUnstakeTokens,
        isBroadcastingUnstakeTokens,
        isClaimingToken,
        signSendTokenError,
        broadcastSendTokenError,
        signStakeTokenError,
        broadcastStakeTokenError,
        signUnstakeTokensError,
        broadcastUnstakeTokensError,
        claimTokenError
      }}>
      {children}
    </WalletContext.Provider>
  )
}

export { useWalletConnect, WalletProvider }
