import axios from 'axios'
import Long from 'long'
import { StdFee } from '@cosmjs/amino'
import { SignerData, DeliverTxResponse } from '@cosmjs/stargate'
import { EncodeObject } from '@cosmjs/proto-signing'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { BroadcastMode, generateEndpointBroadcast, generatePostBodyBroadcast } from '@evmos/provider'
import { isOfflineDirectSigner } from '@cosmjs/proto-signing'
import { fromBase64 } from '@cosmjs/encoding'
import { createTxIBCMsgTransfer, createTxRawEIP712, signatureToWeb3Extension } from '@evmos/transactions'
import { EthSignType } from '@keplr-wallet/types'
import { Buffer } from 'buffer'
import { CHAIN_INFO_LIST, EVMOS_CHAIN_INFO } from '@/config'
import { fatal } from '@/utils'
import { convertDenomToMicroDenom } from '../utils'
import { Account } from '../types'

export type TxRawEip712 = ReturnType<typeof createTxRawEIP712>

// These are the recommended functions to sign and broadcast
// ibc transactions that could come from Evmos to Stride.

// Almost 1:1 API parity with `SigningStargateClient.sign`
const signIbcTransaction = async (
  account: Account,
  address: string,
  messages: EncodeObject[],
  fee: StdFee,
  memo: string,
  explicitSignerData?: SignerData
): Promise<TxRaw | TxRawEip712> => {
  // For normal non-Evmos transactions, we'll operate as usual with Stargate.
  // The code below handles Keplr + Evmos and Ledger + Evmos
  //
  // We'll use account.stargate instead of account.client as stridejs.getSigningStridelabsClient
  // does not contain default amino types for messages such as TX_MSG.MsgTransfer.
  // Let's get rid of this in the future once we've fixed this directly from stride.js
  // @TODO https://github.com/Stride-Labs/interface/issues/81
  if (account.currency.coinDenom !== EVMOS_CHAIN_INFO.stakeCurrency.coinDenom) {
    return await account.stargate.sign(address, messages, fee, memo, explicitSignerData)
  }

  const { accountNumber, sequence } = await account.client.getSequence(account.key.bech32Address)

  const message = messages[0]?.value

  if (message == null) {
    throw fatal('Unable to sign an ibc transaction without any messages.')
  }

  const feeAmount = fee.amount[0]

  // For some reason Ledger transactions expects gas fees (?)
  // @TODO: Let's check this out once we start working on Evmos + Ledger again.
  // We just made some changes with `convertDenomToMicroDenom` and
  // in short, we're already calculating micro denom based on the denom being sent
  // directly on the mutation (useSignSendToken)
  const correctFeeAmount = isOfflineDirectSigner(account.signer)
    ? feeAmount.amount
    : String(convertDenomToMicroDenom(0.05, message.token.denom))

  if (feeAmount == null) {
    throw fatal('Unable to sign an ibc transaction without a fee amount.')
  }

  // We prefer using account over hard-coded EVMOS_CHAIN_INFO so if there's
  // any chain that's like Evmos, there are less things to change.
  const chainInfo = CHAIN_INFO_LIST[account.currency.coinDenom]

  const chain = {
    chainId: 9001,
    cosmosChainId: chainInfo.chainId
  }

  const sender = {
    accountAddress: account.key.bech32Address,
    sequence,
    accountNumber,
    pubkey: Buffer.from(account.key.pubKey).toString('base64')
  }

  const { signDirect, legacyAmino, eipToSign } = createTxIBCMsgTransfer(
    chain,
    sender,
    {
      amount: correctFeeAmount,
      denom: feeAmount.denom,
      gas: fee.gas
    },
    '',
    {
      sourcePort: message.sourcePort,
      sourceChannel: message.sourceChannel,
      amount: message.token.amount,
      denom: message.token.denom,
      receiver: message.receiver,
      revisionNumber: 0,
      revisionHeight: 0,
      timeoutTimestamp: String(message.timeoutTimestamp)
    }
  )

  // non-Ledger signing
  if (isOfflineDirectSigner(account.signer)) {
    const { signature, signed } = await account.signer.signDirect(account.key.bech32Address, {
      bodyBytes: signDirect.body.serializeBinary(),
      authInfoBytes: signDirect.authInfo.serializeBinary(),
      accountNumber: new Long(accountNumber),
      chainId: chainInfo.chainId
    })

    return TxRaw.fromPartial({
      bodyBytes: signed.bodyBytes,
      authInfoBytes: signed.authInfoBytes,
      signatures: [fromBase64(signature.signature)]
    })
  }

  // @TODO: Once we start working again on Evmosgd, let's look into removing this hard-coded
  // call and instead check to use wallet from getWallet (from useConnectWallet) instead.
  if (!window.keplr) {
    throw fatal('Unable to sign ibc transaction from Evmos while Keplr extension is missing.')
  }

  const signature = await window.keplr.signEthereum(
    chainInfo.chainId,
    account.key.bech32Address,
    JSON.stringify(eipToSign),
    EthSignType.EIP712
  )

  const extension = signatureToWeb3Extension(chain, sender, Buffer.from(signature).toString('utf-8'))

  return createTxRawEIP712(legacyAmino.body, legacyAmino.authInfo, extension)
}

const isTxRawEip712 = (data: any): data is TxRawEip712 => {
  return 'message' in data && 'path' in data
}

interface BroadcastResponse {
  tx_response: {
    height: string
    code: number
    txhash: string
    raw_log: string
    data: string
    gas_used: string
    gas_wanted: string
  }
}

// Almost 1:1 API parity with StargateClient.broadcastTx
const broadcastIbcTransaction = async (account: Account, data: TxRaw | TxRawEip712): Promise<DeliverTxResponse> => {
  // Broadcast non-Evmos-Ledger transactions
  // If we got a TxRaw, it means that we'll use Stargate as usual.
  if (!isTxRawEip712(data)) {
    const bytes = TxRaw.encode(data).finish()

    // We'll use account.stargate instead of account.client as stridejs.getSigningStridelabsClient
    // oes not contain default amino types for messages such as TX_MSG.MsgTransfer.
    // Let's get rid of this in the future once we've fixed this directly from stride.js
    // @TODO https://github.com/Stride-Labs/interface/issues/81
    return account.stargate.broadcastTx(bytes)
  }

  const body = JSON.parse(generatePostBodyBroadcast(data, BroadcastMode.Sync))

  // We prefer using account over hard-coded EVMOS_CHAIN_INFO so if there's
  // any chain that's like Evmos, there are less things to change.
  const chainInfo = CHAIN_INFO_LIST[account.currency.coinDenom]

  const instance = axios.create({ baseURL: chainInfo.rest })

  const response = await instance.post<BroadcastResponse>(generateEndpointBroadcast(), body)

  return {
    height: Number(response.data.tx_response.height),
    code: Number(response.data.tx_response.code),
    transactionHash: response.data.tx_response.txhash,
    rawLog: response.data.tx_response.raw_log,
    data: [],
    gasUsed: Number(response.data.tx_response.gas_used),
    gasWanted: Number(response.data.tx_response.gas_wanted)
  }
}

export { signIbcTransaction, broadcastIbcTransaction }
