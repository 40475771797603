import { NotificationsProvider as OriginalNotificationsProvider } from '@mantine/notifications'
import { useMantineTheme } from '@mantine/core'

const NotificationsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const theme = useMantineTheme()

  return (
    <OriginalNotificationsProvider position="bottom-right" zIndex={theme.other.zIndex.notifications} limit={5}>
      {children}
    </OriginalNotificationsProvider>
  )
}

export { NotificationsProvider }
