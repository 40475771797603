import { useAtom } from 'jotai'
import { useMutation, useQueryClient } from 'react-query'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { notify } from '@/atoms/Notifications'
import { fatal, disregard } from '@/utils'
import { queryKeys } from '@/query-keys'
import { useUpdateAccountBalances } from './useUpdateAccountBalances'
import { assertIsDeliverTxSuccess } from '../utils'
import { selectedAccountAtom, strideAccountAtom } from '../atoms'

const useBroadcastUnstakeTokens = () => {
  const [strideAccount] = useAtom(strideAccountAtom)

  const [selectedAccount] = useAtom(selectedAccountAtom)

  const { updateAccountBalances } = useUpdateAccountBalances()

  const client = useQueryClient()

  return useMutation('wallet/broadcast-unstake-tokens', async (raw: TxRaw) => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('You are unable to redeem staked tokens without connecting your wallet.')
    }

    const bytes = TxRaw.encode(raw).finish()

    const tx = await strideAccount.client.broadcastTx(bytes)

    await client.invalidateQueries(queryKeys.transactionHistoryBase)

    assertIsDeliverTxSuccess(tx)

    // We'll update this for precautionary measures
    await client.invalidateQueries(queryKeys.unstakeFormEpochTrackerBase)

    // We'll invalidate redemption records as epoch tracker may not necessarily change
    await client.invalidateQueries(queryKeys.unstakeFormRedemptionRecordBase)

    try {
      await updateAccountBalances(strideAccount)
    } catch (e) {
      notify.error(`An error occured while fetching your updated account balance. Please refresh the page.`)
      disregard(e)
    }
  })
}

export { useBroadcastUnstakeTokens }
