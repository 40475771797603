import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { WalletId } from './types'

// Persisted in the local storage, used to know if we should auto-connect a user to their last used wallet
const defaultConnectionTypeAtom = atomWithStorage<WalletId | undefined>('stride.sessionWalletType', undefined)

// WC QR code + flag to display/hide the WC modal
const wcQrUriAtom = atom<string>('')

const isWalletConnectionModalOpenAtom = atom(false)

const missingExtensionWalletIdAtom = atom<WalletId | undefined>(undefined)

export { defaultConnectionTypeAtom, wcQrUriAtom, isWalletConnectionModalOpenAtom, missingExtensionWalletIdAtom }
