import React, { useState } from 'react'
import { useAtomValue } from 'jotai'
import { Box, Stack, Modal, Title, Text, Group, Image, UnstyledButton, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { WALLET_LIST } from '@/config'
import { isWalletConnectionModalOpenAtom, useWalletConnection } from '@/atoms/WalletConnection'

export const SelectWalletModal: React.FC = () => {
  const { closeModal, selectWallet } = useWalletConnection()

  const isModalOpen = useAtomValue(isWalletConnectionModalOpenAtom)

  const [isDisclaimerExpanded, setIsDisclaimerExpanded] = useState(false)

  const toggleDisclaimerExpanded = () => {
    setIsDisclaimerExpanded((isDisclaimerExpanded) => !isDisclaimerExpanded)
  }

  const theme = useMantineTheme()

  const isMediumScreen = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`, true)

  return (
    <Modal
      opened={isModalOpen}
      onClose={closeModal}
      title="Select a wallet"
      size="lg"
      styles={(t) => ({
        title: {
          fontSize: t.fontSizes.lg,
          fontWeight: 600,
          color: t.colors.gray[9]
        }
      })}>
      <Stack spacing="sm">
        {WALLET_LIST.map((wallet) => (
          <UnstyledButton
            key={wallet.id}
            onClick={() => {
              selectWallet(wallet.id)
            }}>
            <Box
              sx={(t) => ({
                padding: t.spacing.sm,
                background: t.colors.gray[1],
                border: `1px solid ${t.colors.gray[3]}`,
                borderRadius: t.radius.lg
              })}>
              <Group align="center" noWrap>
                <Image
                  src={wallet.logoImgUrl}
                  alt="keplr logo"
                  sx={(t) => ({
                    height: t.other.globalWalletConnectionSelectWalletModal.logoSize,
                    width: t.other.globalWalletConnectionSelectWalletModal.logoSize
                  })}
                />

                <Stack spacing="xs">
                  <Title
                    order={isMediumScreen ? 3 : 4}
                    sx={(t) => ({ lineHeight: t.other.globalWalletConnectionSelectWalletModal.textLineHeight })}>
                    {wallet.name}
                  </Title>

                  <Text
                    sx={(t) => ({
                      color: t.colors.gray[7],
                      lineHeight: t.other.globalWalletConnectionSelectWalletModal.textLineHeight,
                      whiteSpace: 'nowrap'
                    })}>
                    {wallet.description}
                  </Text>
                </Stack>
              </Group>
            </Box>
          </UnstyledButton>
        ))}

        <Box p="sm" sx={(t) => ({ background: t.colors.gray[1], borderRadius: t.radius.lg })}>
          <Stack spacing="sm">
            <Text sx={(t) => ({ color: t.colors.gray[7] })}>
              By connecting a wallet, you acknowledge that you have read and understand the Stride{' '}
              <Text sx={{ display: 'inline', cursor: 'pointer' }} underline onClick={toggleDisclaimerExpanded}>
                Protocol Disclaimer
              </Text>
              .
            </Text>

            {isDisclaimerExpanded && (
              <>
                <Text sx={(t) => ({ color: t.colors.gray[7] })}>
                  Stride is a decentralized peer-to-peer blockchain that people can use to liquid stake their IBC
                  enabled tokens. The Stride blockchain is made up of free, public, and open-source software. Your use
                  of Stride involves various risks, including, but not limited, to losses while digital assets are being
                  supplied to Stride and losses due to validator related mechanisms, such as slashing. Before liquid
                  staking on the Stride blockchain, you should review the relevant documentation to make sure you
                  understand how Stride works. Additionally, just as you can access email protocols, such as SMTP,
                  through multiple email clients, you can access pools on Stride through several web or mobile
                  interfaces. You are responsible for doing your own diligence on those interfaces to understand the
                  fees and risks they present.
                </Text>

                <Text sx={(t) => ({ color: t.colors.gray[7] })}>
                  AS DESCRIBED IN THE STRIDE LICENSES, THE STRIDE PROTOCOL IS PROVIDED “AS IS”, AT YOUR OWN RISK, AND
                  WITHOUT WARRANTIES OF ANY KIND. Although Stride Labs ( “Stride Labs” ) developed much of the initial
                  code for the Stride protocol, it does not provide, own, or control the Stride protocol, which is run
                  by a decentralized validator set. Upgrades and modifications to the protocol are managed in a
                  community-driven way by holders of the ST governance token. No developer or entity involved in
                  creating the Stride protocol will be liable for any claims or damages whatsoever associated with your
                  use, inability to use, or your interaction with other users of the Stride protocol, including any
                  direct, indirect, incidental, special, exemplary, punitive or consequential damages, or loss of
                  profits, cryptocurrencies, tokens, or anything else of value.
                </Text>
              </>
            )}
          </Stack>
        </Box>
      </Stack>
    </Modal>
  )
}
