import React from 'react'
import { Center, MantineColor, MantineSize, useMantineTheme } from '@mantine/core'
import { Icon, IconProps } from '@/components'

export interface StaticActionIconProps {
  icon: IconProps['name']
  size?: MantineSize
  variant?: 'filled' | 'light'
  color?: MantineColor
  round?: boolean
}

// We built this component from scratch hbecause ActionIcon increases font size as we increase overall size.
const StaticActionIcon: React.FC<StaticActionIconProps> = ({
  icon,
  size = 'md',
  variant = 'filled',
  color = 'cyan',
  round = false
}) => {
  const t = useMantineTheme()

  const sizePx = t.other.components.StaticActionIcon.sizes[size]

  return (
    <Center
      sx={{
        height: sizePx,
        width: sizePx,
        background: t.fn.variant({ variant, color }).background,
        borderRadius: round ? '50%' : t.radius.sm
      }}>
      <Icon name={icon} size="sm" fill={variant === 'filled' ? 'white' : t.fn.variant({ variant, color }).color} />
    </Center>
  )
}

export { StaticActionIcon }
