import React from 'react'
import { Text, Title, Space, Group } from '@mantine/core'

export interface StepperModalContentProps {
  title: string
  description: React.ReactNode
  actions?: React.ReactNode
}

const StepperModalContent: React.FC<StepperModalContentProps> = ({ title, description, actions }) => {
  return (
    <>
      <Title order={4} sx={(t) => ({ color: t.colors.gray[9] })}>{title}</Title>

      <Space h="xs" />

      <Text color="gray">{description}</Text>

      {Boolean(actions) && (
        <>
          <Space h="md" />
          <Group spacing="xs">{actions}</Group>
        </>
      )}
    </>
  )
}

export { StepperModalContent }
