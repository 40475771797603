const PLACEHOLDER = '...'

// Puts an ellipsis on the middle
// Removes 3 from the right to take into account for the ellipsis placeholder
const ellipsis = (str: string, limit: number) => {
  if (str.length <= limit || str.length <= PLACEHOLDER.length) return str
  const leftCursor = limit / 2
  const rightCursor = str.length - limit / 2 + PLACEHOLDER.length
  return str.substr(0, leftCursor) + PLACEHOLDER + str.substr(rightCursor)
}

const and = (data: readonly string[], separator = 'and') => {
  const input = [...data]
  const items = input.length
  const lastItem = input.pop()
  return input.length ? `${input.join(', ')}${items > 2 ? ',' : ''} ${separator} ${lastItem}`.trim() : lastItem
}

export { ellipsis, and }
