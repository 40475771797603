import { useMutation, useQueryClient } from 'react-query'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { useAtom } from 'jotai'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { notify } from '@/atoms/Notifications'
import { fatal, disregard } from '@/utils'
import { queryKeys } from '@/query-keys'
import { useUpdateAccountBalances } from './useUpdateAccountBalances'
import { selectedAccountAtom, strideAccountAtom } from '../atoms'
import { broadcastIbcTransaction, traceIbcStatus, IBCTransferStatus, TxRawEip712 } from '../ibc'
import { assertIsDeliverTxSuccess } from '../utils'

export type BroadcastSendTokenMutationMode = 'deposit' | 'withdraw'

export interface BroadcastSendTokenMutationParameters {
  raw: TxRaw | TxRawEip712
  mode: BroadcastSendTokenMutationMode
}

export interface BroadcastSendTokenMutationReturnType {
  tx: DeliverTxResponse
  status: IBCTransferStatus
}

const useBroadcastSendToken = () => {
  const [selectedAccount] = useAtom(selectedAccountAtom)

  const [strideAccount] = useAtom(strideAccountAtom)

  const client = useQueryClient()

  const { updateAccountBalances } = useUpdateAccountBalances()

  return useMutation<BroadcastSendTokenMutationReturnType, void, BroadcastSendTokenMutationParameters>(
    'wallet/broadcast-send-token',
    async ({ raw, mode }) => {
      if (!strideAccount || !selectedAccount) {
        throw fatal('You are unable to send token without connecting your wallet.')
      }

      const account = mode === 'deposit' ? selectedAccount : strideAccount

      // We'll use account.stargate instead of account.client as stridejs.getSigningStridelabsClient
      // oes not contain default amino types for messages such as TX_MSG.MsgTransfer.
      // Let's get rid of this in the future once we've fixed this directly from stride.js
      // @TODO https://github.com/Stride-Labs/interface/issues/81
      const tx = await broadcastIbcTransaction(account, raw)

      await client.invalidateQueries(queryKeys.transactionHistoryBase)

      if (tx.code === 5) {
        throw new InsufficientTokensToTransferError(
          'You have insufficient tokens to complete the transfer. Decrease the stake amount to take into account for the gas fees.'
        )
      }

      assertIsDeliverTxSuccess(tx)

      const status = await traceIbcStatus({ account, tx })

      await client.invalidateQueries(queryKeys.transactionHistoryIbcStatusByHash({ hash: tx.transactionHash }))

      if (status === 'return-later') {
        return {
          tx: tx,
          status: 'return-later'
        }
      }

      try {
        await updateAccountBalances(strideAccount)
        await updateAccountBalances(selectedAccount)
      } catch (e) {
        notify.error(`We could not refresh your transaction history. Please refresh the page upon closing this modal.`)
        disregard(e)
      }

      // We'll handle this after balance is updated because gas fee is still consumed
      // @TODO: It's possible that we may have to wait for funds to be fully refunded
      // @TODO: Handle error - we probably need a flow for this
      if (status === 'timeout') {
        return {
          tx: tx,
          status: 'timeout'
        }
      }

      return {
        tx: tx,
        status: 'complete'
      }
    }
  )
}

class InsufficientTokensToTransferError extends Error {}

export { useBroadcastSendToken, InsufficientTokensToTransferError }
