import { format } from 'date-fns'
import { assertIsDeliverTxSuccess as stargateAssertIsDeliverTxSuccess, DeliverTxResponse } from '@cosmjs/stargate'
import { rollbar } from '@/rollbar'

export const formatTxTimestamp = (timestamp: string | Date): string => {
  return format(new Date(timestamp), 'MMMM d, yyyy h:mm O')
}

// Wraps Stargate's `assertIsDeliverTxSuccess` and logs any transaction errors to Rollbar
export const assertIsDeliverTxSuccess = (result: DeliverTxResponse) => {
  try {
    return stargateAssertIsDeliverTxSuccess(result)
  } catch (e) {
    // @ts-ignore
    rollbar.error(e)
    throw e
  }
}
