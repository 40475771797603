import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import { Container, Anchor, Group, MediaQuery, Space, Stack } from '@mantine/core'
import { useMediumScreen } from '@/hooks'
import { NavigationNetworkStatus } from './NavigationNetworkStatus'
import { NavigationMenu } from './NavigationMenu'
import { TopBanner } from './TopBanner'
import logo from '@/images/logo.svg'

const NavigationWalletDropdown = dynamic<{}>(
  () => import('./NavigationWalletDropdown').then((module) => module.NavigationWalletDropdown),
  { ssr: false }
)

const Navigation: React.FC = () => {
  const { isMediumScreen } = useMediumScreen()

  return (
    <>
      <TopBanner />

      <Container size="xl" px={16}>
        <Group align="center" position="apart" py={16}>
          <Link href="/" passHref>
            <Anchor inline>
              <Image src={logo} alt="Stride Logo" />
            </Anchor>
          </Link>

          <Group spacing="lg">
            <NavigationNetworkStatus />
            {isMediumScreen && (
              <Group spacing="sm">
                <NavigationMenu />
                <NavigationWalletDropdown />
              </Group>
            )}
          </Group>
        </Group>

        {!isMediumScreen && (
          <Stack spacing="xs">
            <NavigationMenu />
            <NavigationWalletDropdown />
          </Stack>
        )}
      </Container>
      <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
        <Space h="xs" />
      </MediaQuery>
    </>
  )
}

export { Navigation }
