import { useRef } from 'react'
import { useMount } from '@/hooks'

const useAutoFocusRef = () => {
  const ref = useRef<HTMLInputElement>(null)

  useMount(() => {
    ref.current?.focus()
  })

  return ref
}

export { useAutoFocusRef }
